import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg";
import { ReactComponent as NoAvatar } from "../../Assets/icons/calls/noAvatar.svg";
import { colors } from "../../Assets/styles/colors";
import { displayName } from "../../Helpers/chatHelper/NameHelper";
import { useAuthService } from "../../Context/AuthContext";
import { useIsSpeaking, useParticipantTile } from "@livekit/components-react";

const ParticipantComponentTranslationEnabled = ({ participant, callInfo, grid, focused }) => {
    const divRef = useRef(null);

    let tracks = participant.getTrackPublications();
    const [vTrack, setVTrack] = useState(null);
    const videoRef = useRef(null);
    const [participantInfoFromCall, setParticipantInfoFromCall] = useState(null);
    const { user } = useAuthService();
    const isUserspeaking = useIsSpeaking(participant);
    const [fontSize, setFontSize] = useState(16);
    const [width, setWidth] = useState(300)
    const [avatarSize, setAvatarSize] = useState(80)


    const getParticipantInfoFromCall = () => {
        let info;
        let foundInOthers = callInfo.other_participants.find(
            (p) => p.id == participant.identity
        );

        if (participant.identity == callInfo.created_by.id) {
            info = callInfo.created_by;
        } else if (foundInOthers) {
            info = foundInOthers;
        } else {
            info = user;
        }

        if (info) {
            setParticipantInfoFromCall(info);
        }
    };

    const displayParticipantAvatar = (participant) => {
        if (!participant) {
            return <NoAvatar />;
        }

        let displayAvatar = <></>;
        let profilePicture = participant.profile_picture;

        if (profilePicture) {
            displayAvatar = <img src={profilePicture} alt="avatar" />;
        } else {
            displayAvatar = <NoAvatar />;
        }

        return displayAvatar;
    };

    useEffect(() => {

        let videoTrack = tracks.find(
            (track) => track.kind === "video" && track.track != null
        );
        if (videoTrack && videoTrack?.track) {
            setVTrack(videoTrack);
            videoTrack.track.attach(videoRef.current);
        } else {
            setVTrack(null);
        }
    }, [tracks]);

    useState(() => {
        getParticipantInfoFromCall();
    }, [participant]);


    useEffect(() => {
        const adjustFontSize = () => {
            if (divRef.current) {
                const { width, height } = divRef.current.getBoundingClientRect();
                // Example: Set font size based on width or height
                const newFontSize = Math.min(width / 10, height / 10, 40); // Adjust divisor and max value as needed
                const newAvatarSize = Math.min(width / 3, height / 3, 80); // Adjust divisor and max value as needed
                setFontSize(newFontSize);
                setWidth(width)
                setAvatarSize(newAvatarSize)
            }
        };

        adjustFontSize(); // Adjust initially
        window.addEventListener("resize", adjustFontSize); // Adjust on resize
        return () => window.removeEventListener("resize", adjustFontSize);
    }, []);


    return (
        <ConnectingContainer ref={divRef} speaking={isUserspeaking || focused}>
            {vTrack ? (
                <>
                    <VideoContainer>
                        <video ref={videoRef} autoPlay muted playsInline />
                    </VideoContainer>
                    <NameBadge>
                        <p> {participantInfoFromCall.name}</p>
                    </NameBadge>
                </>
            ) : (
                <AvatarWrapper>
                    <UserAvatar avatarSize={avatarSize}>
                        {displayParticipantAvatar(participantInfoFromCall)}
                    </UserAvatar>
                    <Name fontSize={fontSize}>{participantInfoFromCall?.name}</Name>

                </AvatarWrapper>
            )}
        </ConnectingContainer>
    );
};

export default ParticipantComponentTranslationEnabled;
const ConnectingContainer = styled.div`
  display: flex;
    flex:1 0 calc(25% - 10px);
    border:${props => props.speaking ? `3px solid ${colors.purple}` : "unset"};
    padding:${props => props.speaking ? `-3px` : "0"};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.callComponent.darkColor};
  overflow: hidden;
  border-radius: 10px;
  min-height:200px;
    position: relative;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  margin: 5px;
  overflow: hidden;
`;

const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  object-fit: contain;
  position: relative;
  overflow: hidden;

    max-height: ${props => props.avatarSize}px;
    max-width: ${props => props.avatarSize}px;
    min-height: ${props => props.avatarSize}px;
    min-width: ${props => props.avatarSize}px;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    display:flex;
    overflow:hidden;
    position: relative;
    height: ${props => props.avatarSize}px;
    width: ${props => props.avatarSize}px;
  }
`;

const Status = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${colors.callControlBar.buttonLabelColor};
`;
const Name = styled.h3`
font-size:${props => props.fontSize}px;
  color: ${colors.white};
text-align:center;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`
const NameBadge = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 10px;
  left: 10px;
  border-radius: 4px;
  p {
    font-size: 12px;
    color: ${colors.white};
  }
`;
