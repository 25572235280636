import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import ReactGA from "react-ga4";
import {
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPhoneNumber,
  signOut,
} from "firebase/auth";
import { auth } from "../firebase";
import {
  getCurrentUserApi,
  getCurrentUserAPINoRedirectCall,
  getCustomTokenAPICall,
  getPlatformSubscriptionAPICall,
  loginWithGoogleAPICall,
  loginWithSSOAPICall,
} from "../API/users/getCurrentUser";
import { updateUserApi } from "../API/users/updateUser";
import { refreshTokenApi } from "../API/users/refreshToken";
import { deleteUserApi } from "../API/users/deleteUser";
import {
  registerUserApi,
  registerUserWiwthSSOAPICALL,
} from "../API/users/registerUser";
import LocalStorage from "../Helpers/LocalStorage";
import { updateUserProfilePicturesApi } from "../API/users/setUserProfilePicture";
import {
  getCallLanguagesAPI,
  getTranslationsLanguagesAPI,
} from "../API/users/getTranslationsLanguages";
import { logoutUserAPICAll } from "../API/users/logoutUser";
import { useCookies } from "react-cookie";
import { Purchases } from "@revenuecat/purchases-js";
import {
  cancelStripeSubscriptionAPICall,
  getStripePaymentLinkAPICall,
  hadFreeTrialAPICall,
} from "../API/payments/paymentsService";
import { unregister } from "../serviceWorker";
import { getNumberOfReferralsAPICall } from "../API/users/numberOfReferrals";
import { useLinksquaredService } from "../Context/LinksquaredContext";
import {
  SUBSCRIPTION_LEVEL_PRO,
  SUBSCRIPTION_LEVEL_ULTRA,
  SUBSCRIPTION_LEVEL_PLATFORM,
  SUBSCRIPTION_LEVEL_UNSUBSCRIBED,
  SUBSCRIPTION_LEVEL_REFERRALS,
} from "../Constants/constantValues";
import {
  isSubscriptionLevelEnoughForAccessLevel,
  ACCESS_LEVEL,
} from "../Helpers/subscriptions/SubscriptionsHelper";

export const AuthContext = createContext({
  user: [],
  userCustomerInfo: [],
  isAuthenticated: [],
  translationsLanguages: [],
  callTranslationsLanguages: [],
  referrals: [],
  callTranslationsLanguagesRef: [],
  subscriptionLevel: [],
  subscription: [],

  signInWithPhone: () => { },
  verifyOtpCodeAndSignIn: () => { },
  createUser: () => { },
  getCurrentUser: () => { },
  singOutFirebase: () => { },
  updateUser: () => { },
  refreshToken: () => { },
  deleteUser: () => { },
  updateUserProfilePictures: () => { },
  getTranslationsLanguages: () => { },
  handleLoginUser: () => { },
  updateUserWithNewPushToken: () => { },
  setPushToken: () => { },
  pushToken: [],
  hadFreeTrial: () => { },
  getStripePaymentLink: () => { },
  cancelStripeSubscription: () => { },
  checkSubscriptions: () => { },
  getCustomToken: () => { },
  signInWithCustomAcessToken: () => { },
  getSSoLoginLink: () => { },
  handleSignOutUser: () => { },
  getCallTranslationsLanguages: () => { },
  getNumberOfReferrals: () => { },
  getGoogleSSoLoginLink: () => { },
  registerUserWiwthSSO: () => { },
  getPlatformSubscription: () => { },
  handleLogoutUser: () => { },
  getCurrentUserNoRedirect: () => { },
  checkIfUserHasPermisionFor: () => { },
});

const AuthContextProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["notificationsModal"]);
  const [pushToken, setPushToken] = useState(null);
  const [user, setUser] = useState(undefined);
  const [translationsLanguages, setTranslationsLanguages] = useState([]);
  const [callTranslationsLanguages, setCallTranslationsLanguages] = useState(
    []
  );
  const callTranslationsLanguagesRef = useRef([]);
  const [userCustomerInfo, setUserCustomerInfo] = useState(null);
  const [referrals, setReferrals] = useState(0);

  const [subscriptionLevel, setSubscriptionLevel] = useState("not_subscribed");
  const [subscription, setSubscription] = useState(null);

  const { setUserDetails } = useLinksquaredService();

  const REVENUE_CAT_KEY = process.env.REACT_APP_REVENUE_CAT_KEY;

  const handleLoginUser = (user) => {
    setUser(user);
    setUserDetails(user);
  };

  const handleLogoutUser = () => {
    // SET GA ANALYTICS userID null
    ReactGA.set({ user_id: null });
    LocalStorage.removeAuthenticationToken();
    LocalStorage.removeRefreshToken();
    LocalStorage.removeSSOFlag();
    setUser(null);
  };

  const checkIfUserHasPermisionFor = async (
    accessLevel,
    allowedCompletion,
    unallowedCompletion,
    unsubscribedCompletion
  ) => {
    checkSubscriptions((subscriptionLevel) => {
      if (subscriptionLevel === SUBSCRIPTION_LEVEL_UNSUBSCRIBED) {
        unsubscribedCompletion();
        return;
      }

      const valid = isSubscriptionLevelEnoughForAccessLevel(
        subscriptionLevel,
        accessLevel
      );
      if (valid) {
        allowedCompletion();
      } else {
        unallowedCompletion();
      }
    });
  };

  const checkSubscriptions = async (completion) => {
    try {
      const customerInfo =
        await Purchases.getSharedInstance().getCustomerInfo();



      if (
        customerInfo.entitlements.active.Pro &&
        customerInfo.entitlements.active.Pro.isActive
      ) {
        setSubscription(customerInfo.entitlements.active.Pro);
        setSubscriptionLevel(SUBSCRIPTION_LEVEL_PRO);
        completion(SUBSCRIPTION_LEVEL_PRO);
        return;
      }

      if (
        customerInfo.entitlements.active.Ultra &&
        customerInfo.entitlements.active.Ultra.isActive
      ) {
        setSubscription(customerInfo.entitlements.active.Ultra);
        setSubscriptionLevel(SUBSCRIPTION_LEVEL_ULTRA);
        completion(SUBSCRIPTION_LEVEL_ULTRA);

        return;
      }

      getPlatformSubscription(
        (response) => {
          const platformSubscription = response.data.platform_subscription;

          if (
            platformSubscription &&
            platformSubscription.active &&
            platformSubscription.status === "active"
          ) {

            setSubscription(platformSubscription);
            setSubscriptionLevel(SUBSCRIPTION_LEVEL_PLATFORM);
            completion(SUBSCRIPTION_LEVEL_PLATFORM);
            return;
          } else if (
            customerInfo.entitlements.active.Referrals &&
            customerInfo.entitlements.active.Referrals.isActive
          ) {

            setSubscription(customerInfo.entitlements.active.Referrals);
            setSubscriptionLevel(SUBSCRIPTION_LEVEL_REFERRALS);
            completion(SUBSCRIPTION_LEVEL_REFERRALS);
            return;
          } else {

            setSubscription(null);
            setSubscriptionLevel(SUBSCRIPTION_LEVEL_UNSUBSCRIBED);
            completion(SUBSCRIPTION_LEVEL_UNSUBSCRIBED);
            return;
          }
        },
        () => { }
      );
      // access latest customerInfo
    } catch (e) {
      // Handle errors fetching customer info
    }
  };

  const signInWithPhone = (phoneNumber, appVerifier, onSucces, onError) => {
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        onSucces(confirmationResult);
        //   window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        onError(error);
      });
  };

  const signInWithCustomAcessToken = (customAccessToken, onSucces, onError) => {
    signInWithCustomToken(auth, customAccessToken)
      .then((userCredential) => {
        // Signed in
        const userResponse = userCredential.user;
        LocalStorage.setAuthenthicationToken(userResponse.accessToken);
        LocalStorage.setRefreshToken(userResponse.stsTokenManager.refreshToken);
        LocalStorage.removeSSOFlag();
        onSucces(userCredential);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const verifyOtpCodeAndSignIn = (
    confirmationResult,
    code,
    onSucces,
    onError
  ) => {
    confirmationResult
      .confirm(code)
      .then((result) => {
        onSucces(result);
        // ...
      })
      .catch((error) => {
        onError(error);
        // User couldn't sign in (bad verification code?)
        // ...
      });
  };

  const refreshToken = useCallback(
    async (refreshToken, onSucces, errorCallback) => {
      try {
        await refreshTokenApi(
          refreshToken,
          function (response) {
            onSucces(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback();
      }
    }
  );

  const singOutFirebase = () => {
    setCookie("notificationsModal", null);
    signOut(auth)
      .then(() => {
        const refresh_token = LocalStorage.getRefreshToken();
        if (refresh_token && refresh_token != "undefined") {
          handleSignOutUser();
        } else {
          handleLogoutUser();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrentUser = useCallback(async (onSucces, errorCallback) => {
    try {
      await getCurrentUserApi(
        function (response) {
          handleLoginUser(response.data.user);
          //SET USER ID FOR GA ANALYTICS
          const user_id = response.data.user.id;
          ReactGA.set({ user_id: user_id });
          const purchases = Purchases.configure(
            REVENUE_CAT_KEY,
            user_id.toString()
          );
          checkSubscriptions();
          onSucces(response);
        },
        function (error) {
          setUser(null);
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback();
    }
  });

  const getCurrentUserNoRedirect = useCallback(
    async (onSucces, errorCallback) => {
      try {
        await getCurrentUserAPINoRedirectCall(
          function (response) {
            handleLoginUser(response.data.user);
            //SET USER ID FOR GA ANALYTICS
            const user_id = response.data.user.id;
            ReactGA.set({ user_id: user_id });
            const purchases = Purchases.configure(
              REVENUE_CAT_KEY,
              user_id.toString()
            );
            checkSubscriptions();
            onSucces(response);
          },
          function (error) {
            console.log("error", error);
            setUser(null);
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback();
      }
    }
  );

  const getTranslationsLanguages = useCallback(
    async (onSucces, errorCallback) => {
      try {
        await getTranslationsLanguagesAPI(
          function (response) {
            onSucces(response);
            setTranslationsLanguages(response.data.languages);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback();
      }
    }
  );

  const getCallTranslationsLanguages = useCallback(
    async (onSucces, errorCallback) => {
      try {
        await getCallLanguagesAPI(
          function (response) {
            onSucces(response);
            callTranslationsLanguagesRef.current = response.data.languages;
            setCallTranslationsLanguages(response.data.languages);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback();
      }
    }
  );

  const getNumberOfReferrals = useCallback(async (onSucces, errorCallback) => {
    try {
      await getNumberOfReferralsAPICall(
        function (response) {
          onSucces(response);
          setReferrals(response.data.referrals);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback();
    }
  });

  const createUser = useCallback(async (data, onSuccess, errorCallback) => {
    try {
      await registerUserApi(
        data,
        function (response) {
          handleLoginUser(response.data.user);
          const user_id = response.data.user.id;
          const purchases = Purchases.configure(
            REVENUE_CAT_KEY,
            user_id.toString()
          );
          onSuccess(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const getCustomToken = useCallback(async (onSuccess, errorCallback) => {
    try {
      await getCustomTokenAPICall(
        function (response) {
          onSuccess(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const getPlatformSubscription = useCallback(
    async (onSuccess, errorCallback) => {
      try {
        await getPlatformSubscriptionAPICall(
          function (response) {
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  const updateUser = useCallback(async (data, onSuccess, errorCallback) => {
    try {
      await updateUserApi(
        data,
        function (response) {
          handleLoginUser(response.data.user);
          onSuccess(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const registerUserWiwthSSO = useCallback(
    async (data, onSuccess, errorCallback) => {
      try {
        await registerUserWiwthSSOAPICALL(
          data,
          function (response) {
            handleLoginUser(response.data.user);
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  const deleteUser = useCallback(async (reasons, onSucces, errorCallback) => {
    try {
      await deleteUserApi(
        reasons,
        function (response) {
          signOut(auth)
            .then(() => {
              handleLogoutUser();
            })
            .catch((error) => {
              console.log(error);
            });

          onSucces(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const updateUserProfilePictures = useCallback(
    async (data, onSuccess, errorCallback) => {
      try {
        await updateUserProfilePicturesApi(
          data,
          function (response) {
            handleLoginUser(response.data.user);
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  const signOutUser = useCallback(async (onSuccess, errorCallback) => {
    try {
      await logoutUserAPICAll(
        pushToken,
        function (response) {
          setPushToken(null);
          onSuccess(response);
          unregister();
          LocalStorage.removeSSOFlag();
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const handleSignOutUser = () => {
    signOutUser(
      () => {
        handleLogoutUser();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const hadFreeTrial = useCallback(async (onSuccess, errorCallback) => {
    try {
      await hadFreeTrialAPICall(
        function (response) {
          onSuccess(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const getStripePaymentLink = useCallback(
    async (type, onSuccess, errorCallback) => {
      try {
        await getStripePaymentLinkAPICall(
          type,
          function (response) {
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  const cancelStripeSubscription = useCallback(
    async (onSuccess, errorCallback) => {
      try {
        await cancelStripeSubscriptionAPICall(
          function (response) {
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  const updateUserWithNewPushToken = (token) => {
    var objectData = {};
    objectData.push_token = token;
    objectData.push_token_platform = "web";
    updateUser(
      objectData,
      (response) => { },
      (error) => { }
    );
  };

  const getSSoLoginLink = useCallback(async (onSuccess, errorCallback) => {
    try {
      await loginWithSSOAPICall(
        function (response) {
          onSuccess(response);
        },
        function (error) {
          errorCallback(error);
        }
      );
    } catch (err) {
      errorCallback(err);
    }
  });

  const getGoogleSSoLoginLink = useCallback(
    async (onSuccess, errorCallback) => {
      try {
        await loginWithGoogleAPICall(
          function (response) {
            onSuccess(response);
          },
          function (error) {
            errorCallback(error);
          }
        );
      } catch (err) {
        errorCallback(err);
      }
    }
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        userCustomerInfo,
        signInWithPhone,
        verifyOtpCodeAndSignIn,
        createUser,
        getCurrentUser,
        singOutFirebase,
        updateUser,
        refreshToken,
        deleteUser,
        updateUserProfilePictures,
        getTranslationsLanguages,
        translationsLanguages,
        handleLoginUser,
        updateUserWithNewPushToken,
        setPushToken,
        pushToken,
        hadFreeTrial,
        getStripePaymentLink,
        cancelStripeSubscription,
        checkSubscriptions,
        getCustomToken,
        signInWithCustomAcessToken,
        getSSoLoginLink,
        handleSignOutUser,
        callTranslationsLanguages,
        getCallTranslationsLanguages,
        getNumberOfReferrals,
        referrals,
        getGoogleSSoLoginLink,
        registerUserWiwthSSO,
        callTranslationsLanguagesRef,
        getPlatformSubscription,
        handleLogoutUser,
        getCurrentUserNoRedirect,
        subscription,
        subscriptionLevel,
        checkIfUserHasPermisionFor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthService = () => useContext(AuthContext);

export default AuthContextProvider;
