import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { InfiniteScroll } from "stream-chat-react";
import CallTranslatedMessage from "./CallTranslatedMessage";
import { ReactComponent as TapToSpeakIcon } from "../../Assets/icons/callControlButtons/tapToSpeak.svg";
import { ReactComponent as StopSpeakingIcon } from "../../Assets/icons/callControlButtons/stopSpeaking.svg";
import { ReactComponent as WaitToSpeak } from "../../Assets/icons/callControlButtons/waitToSpeak.svg";
import SimpleRecorderService from "./SimpleRecorderService";
import TranslatedMessageSpeaker from "./TranslatedMessageSpeaker";
import { useAuthService } from "../../Context/AuthContext";
import { useLocalParticipant } from "@livekit/components-react";
import { Track } from "livekit-client";
import { localizationStrings } from "../../Localization/Localization";
import { ThreeDots } from "react-loader-spinner";
import { notifyInfo } from "../../Helpers/Notifications";

const SpeakingTranslator = ({
  messages,
  handleTranscribeAudio,
  speakMessagesList,
  speechRecongnitionMessages,
  speakStatus,
  setSpeakStatus,
  handleSendCancelSpeakMessage,
  handleRequestSpeakMessage,
  speakersQueue,
  muteMicrophone,
  unmuteMicrophone,
  micEnabledRef,
}) => {
  const ENDED = "ended";
  const REQUESTED = "requested";
  const SPEAKING = "speaking";

  const recorderServiceRef = useRef(null);
  if (!recorderServiceRef.current) {
    recorderServiceRef.current = new SimpleRecorderService();
  }

  const [ahedUsers, setAheadUsers] = useState(null);
  const { user, callTranslationsLanguages } = useAuthService();
  const recordingStartedRef = useRef(false);
  const [translatingMessage, setTranslatingMessage] = useState(false);
  const timerPassedRef = useRef(false);
  const timerRef = useRef(null);
  const [shouldWaitForSpeak, setShouldWaitForSpeak] = useState(false);
  const prevSpeakersQueue = useRef([]);

  let foundLanguage = callTranslationsLanguages?.find(
    (item) => item.locale === user.call_language
  );

  const blobToFile = (theBlob, fileName) => {
    let file = new File([theBlob], fileName, {
      lastModified: new Date(),
      type: "audio/mp3",
    });
    return file;
  };

  const startRecorderService = async () => {
    if (!micEnabledRef.current) {
      return;
    }
    await recorderServiceRef.current.start(handleBlob);
    recordingStartedRef.current = true;
  };

  const handleBlob = (blob) => {
    const audioFile = blobToFile(blob, "audio.mp3");
    setTranslatingMessage(true);
    handleTranscribeAudio(audioFile, () => { });
  };

  const stopRecorderService = async () => {
    await recorderServiceRef.current.stop();
  };

  const handleStopSpeaking = () => {
    stopRecorderService();
    handleSendCancelSpeakMessage();
    setSpeakStatus(ENDED);
    muteMicrophone();
  };

  const handleRequestSpeak = () => {
    handleRequestSpeakMessage();
    setSpeakStatus(REQUESTED);
  };

  const calculateQueue = (speakersQueue) => {
    let foundIndex = speakersQueue.findIndex(
      (item) => item.user_id === user.id
    );
    if (foundIndex > -1) {
      setAheadUsers(foundIndex);
    } else {
      setAheadUsers(null);
    }
  };

  const setSpeakTurn = () => {
    if (speakStatus === SPEAKING) {
      return;
    }

    unmuteMicrophone().then(() => {
      setSpeakStatus(SPEAKING);
      startRecorderService();
    });
  };

  const setSpeakTurnIfNeeded = () => {
    if (speakersQueue[0]?.user_id != user.id) {
      return;
    }

    if (
      prevSpeakersQueue.current.length === 0 ||
      !shouldWaitForSpeak ||
      timerPassedRef.current
    ) {
      setSpeakTurn();
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    muteMicrophone();
    return () => {
      stopRecorderService();
    };
  }, []);

  useEffect(() => {
    setTranslatingMessage(false);
  }, [speechRecongnitionMessages]);

  useEffect(() => {
    calculateQueue(speakersQueue);

    if (speakersQueue[0]?.user_id === user.id) {
      timerRef.current = setTimeout(() => {
        timerPassedRef.current = true;
        setSpeakTurnIfNeeded();
      }, 10000);

      if (prevSpeakersQueue.current.length === 0) {
        setSpeakTurnIfNeeded();
      }
    }

    timerPassedRef.current = false;
    prevSpeakersQueue.current = speakersQueue;
    setShouldWaitForSpeak(true);
  }, [speakersQueue]);

  useEffect(() => {
    setSpeakTurnIfNeeded();
  }, [shouldWaitForSpeak]);

  useEffect(() => {
    return () => {
      if (micEnabledRef.current === false) {
        notifyInfo(localizationStrings.request_translation_modal.mic_info)
      }

    }
  }, [])

  return (
    <Container>
      <TranslatedMessageSpeaker
        speakMessagesList={speakMessagesList}
        setShouldWaitForSpeak={setShouldWaitForSpeak}
      />

      <MessagesList id="messageListContainer">
        <InfiniteScroll
          dataLength={messages?.length}
          scrollableTarget="messageListContainer"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
          }}
        >
          {speechRecongnitionMessages.length === 0 && (
            <InfoBlock>
              <p>
                {localizationStrings.speech_container.note}{" "}
                {foundLanguage?.name} ({foundLanguage?.translation}){" "}
                {localizationStrings.speech_container.note2}
              </p>
            </InfoBlock>
          )}

          {(micEnabledRef.current || translatingMessage) && (
            <DotsWrapper>
              <ListenContainer>
                {micEnabledRef.current && (
                  <p> {localizationStrings.speech_container.speak_now} </p>
                )}
                <ListenerWrapper>
                  <ThreeDots
                    visible={true}
                    height={"15"}
                    width={"40"}
                    color={colors.purple}
                  />
                </ListenerWrapper>
              </ListenContainer>
            </DotsWrapper>
          )}

          {speechRecongnitionMessages.map((message, index) => (
            <CallTranslatedMessage
              faded={index !== 0}
              key={index}
              message={message}
              ownMessage={message.user_id === user.id}
            />
          ))}
        </InfiniteScroll>
      </MessagesList>

      <Bottom>
        {speakStatus === ENDED && (
          <TapToSpeakBtn onClick={() => handleRequestSpeak()}>
            <TapToSpeakIcon /> Tap to speak
          </TapToSpeakBtn>
        )}
        {speakStatus === REQUESTED && (
          <WaitToSpeakBtn onClick={() => handleStopSpeaking()}>
            <WaitToSpeak />
            <TextWrapper>
              <Text>
                Wait to speak{" "}
                {ahedUsers !== null && ahedUsers !== 0 && (
                  <label>, {ahedUsers} ahead.</label>
                )}
              </Text>
              <CancelText>Tap to cancel</CancelText>
            </TextWrapper>
          </WaitToSpeakBtn>
        )}
        {speakStatus === SPEAKING && (
          <FinishSpeaking onClick={() => handleStopSpeaking()}>
            <StopSpeakingIcon /> Finish speaking
          </FinishSpeaking>
        )}
      </Bottom>
    </Container>
  );
};

export default SpeakingTranslator;

const ListenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  gap: 20px;

  color: ${colors.darkPurple};
  font-size: 16px;
  font-weight: 500;

  p {
    margin-top: 20px;
  }
`;

const ListenerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: ${colors.chatElementBgSelected};
  margin-left: auto;
  min-height: 30px;
  min-width: 70px;
  margin-top: 20px;
  margin-right: 20px;
`;

const DotsWrapper = styled.div`
  display: flex;
  min-height: 30px;
`;

const InfoBlock = styled.div`
  p {
    font-size: 16px;
    color: ${colors.chatElementColor};
    padding: 20px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  max-width: 400px;
  overflow: hidden;
`;
const MessagesList = styled.div`
  display: flex;
  height: 100%;
  border-radius: 10px;
  background: ${colors.callComponent.translatedListMessagesBg};
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 0;
`;
const TapToSpeakBtn = styled.button`
  display: flex;
  width: 100%;
  padding: 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${colors.white};
  background: ${colors.purple};
  border: none;
  border-radius: 10px;
  max-height: 60px;
  svg {
    max-width: 12px;
    max-height: 24px;
  }
  cursor: pointer;
`;

const FinishSpeaking = styled(TapToSpeakBtn)`
  background: ${colors.white};
  color: ${colors.callComponent.darkColor};
`;

const Bottom = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding: 18px 0 10px;
`;
const WaitToSpeakBtn = styled(TapToSpeakBtn)`
  background: rgba(65, 64, 131, 1);
  svg {
    max-width: 20px;
    max-height: 24px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: ${colors.white};
  label {
    font-weight: 600;
  }
`;
const CancelText = styled.p`
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  color: rgba(255, 255, 255, 0.62);
`;
