import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import PhoneInput from "react-phone-number-input";
import { localizationStrings } from "../../Localization/Localization";
import { useConversationService } from "../../Context/ConversationsContext";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import ToggleList from "../GenericComponents/ToggleList";
import { EMAIL, PHONE } from "../../Constants/constantValues";

const AddNewChatContact = ({
  user,
  setIsAddNewContactOpen,
  setLoading,
  loadContacts,
}) => {
  const { addContact } = useConversationService();
  const initialValues = {
    firstName: "",
    lastName: "",
    phone: "",
    groupName: "",
    email: "",
  };

  const [phone, setPhone] = useState(initialValues.phone);
  const [inputValues, setInputValues] = useState(initialValues);
  const [addBtnDisabled, setAddBtnDisabled] = useState(true);
  const [toggle, setToggle] = useState(PHONE);
  const [fullName, setFullName] = useState("");

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const addNewContact = (phone, email) => {
    setLoading(true);
    addContact(
      fullName,
      phone,
      email,
      function (response) {
        notifySuccess(localizationStrings.profile.contact_added);
        loadContacts();
        setIsAddNewContactOpen(false);
        setLoading(false);
      },
      function (error) {
        notifyError(localizationStrings.profile.something_wrong);
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleAddNewContact = () => {
    if (toggle === PHONE) {
      addNewContact(phone, null);
    }
    if (toggle === EMAIL) {
      addNewContact(null, inputValues.email);
    }
  };

  const isNameValid = () => {
    if (inputValues.firstName || inputValues.lastName) {
      return true;
    } else {
      return false;
    }
  };

  const checkDisabled = () => {
    if (toggle === PHONE) {
      if (phone?.length < 7 || !phone || !isNameValid()) {
        setAddBtnDisabled(true);
      } else {
        setAddBtnDisabled(false);
      }
    }

    if (toggle === EMAIL) {
      if (inputValues.email.length < 5 || !isNameValid()) {
        setAddBtnDisabled(true);
      } else {
        setAddBtnDisabled(false);
      }
    }
  };

  useEffect(() => {
    setFullName(inputValues.firstName + " " + inputValues.lastName);
  }, [inputValues.firstName, inputValues.lastName]);

  useEffect(() => {
    checkDisabled();
  }, [phone, inputValues, toggle]);

  return (
    <AddNewGroupContainer>
      <Container>
        <BackButton onClick={() => setIsAddNewContactOpen(false)}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.new_chat.new_contact}</h1>
        </ProfileTitle>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "end" }}
        ></div>
      </Container>

      <CardInputsWrapper>
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.new_chat.name}</h1>
          </TitleWrapper>

          <CustomInput
            name="firstName"
            id="firstName-input"
            placeholder={localizationStrings.new_chat.first_name}
            value={inputValues.firstName}
            onChange={(e) => inputChangeHandler(e)}
            maxLength={100}
          />

          <CustomInput
            name="lastName"
            id="lastName-input"
            placeholder={localizationStrings.new_chat.last_name}
            value={inputValues.lastName}
            onChange={(e) => inputChangeHandler(e)}
            maxLength={100}
          />
        </Group>

        <Group style={{ alignItems: "center" }}>
          <ToggleContainer>
            <ToggleButton
              $active={toggle === PHONE}
              onClick={(e) => setToggle(PHONE)}
            >
              {localizationStrings.profile.phone}
            </ToggleButton>
            <ToggleButton
              $active={toggle === EMAIL}
              onClick={(e) => setToggle(EMAIL)}
            >
              {localizationStrings.profile.email}
            </ToggleButton>
          </ToggleContainer>
        </Group>
        {toggle === PHONE && (
          <Group>
            <TitleWrapper>
              <h1>{localizationStrings.profile.phone_number}</h1>
            </TitleWrapper>
            <CustomPhoneWrapper>
              <PhoneInput
                showDropdown={false}
                international
                defaultCountry={"RO"}
                name="phoneNumber"
                placeholder={localizationStrings.profile.phone_number}
                value={phone}
                onChange={(e) => setPhone(e)}
                buttonClass="inputClass"
                dropdownClass="inputClass"
                containerClass="inputClass"
                limitMaxLength
              />
            </CustomPhoneWrapper>
          </Group>
        )}
        {toggle === EMAIL && (
          <Group>
            <TitleWrapper>
              <h1>{localizationStrings.profile.email}</h1>
            </TitleWrapper>
            <CustomInput
              name="email"
              id="email-input"
              placeholder={localizationStrings.new_chat.email_placeholder}
              value={inputValues.email}
              onChange={(e) => inputChangeHandler(e)}
              maxLength={100}
            />
          </Group>
        )}
        <Group>
          <AddNewContactButton
            disabled={addBtnDisabled}
            onClick={() => handleAddNewContact()}
          >
            {localizationStrings.new_chat.add_contact}
          </AddNewContactButton>
        </Group>
      </CardInputsWrapper>
    </AddNewGroupContainer>
  );
};

export default AddNewChatContact;

const AddNewGroupContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;

const ProfileTitle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  min-width: 140px;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;

const CardInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CustomInput = styled.input`
  background: ${colors.newChatModalInputBg};
  padding: 10px 16px;
  border-radius: 10px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${colors.white};
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-weight: 500;
  }
`;

const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  padding: 0 0 0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-transform: uppercase;
  }
`;
const ConfirmButton = styled(BackButton)`
  color: ${colors.purple};
  margin-left: auto;
`;
const AddNewContactButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${colors.purple};
  color: ${colors.white};
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  border: none;
  border-radius: 10px;
  padding: 14px 15px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${colors.purple};
  padding: 3px;
  border-radius: 16px;
  gap: 10px;
  width: fit-content;
`;
const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.white};
  background: none;
  cursor: pointer;
  background: ${(props) =>
    props.$active ? colors.chaetElementBgSelected : "unset"};
  &:first-child {
    border-radius: 16px 0 0 16px;
  }
  &:last-child {
    border-radius: 0 16px 16px 0;
  }
  min-width: 90px;
`;
