import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg";
import { ReactComponent as NoAvatar } from "../../Assets/icons/calls/noAvatar.svg";
import { colors } from "../../Assets/styles/colors";
import { displayName } from "../../Helpers/chatHelper/NameHelper";
import { useAuthService } from "../../Context/AuthContext";
import { useIsSpeaking, useParticipantTile } from "@livekit/components-react";

const ScreenShareParticipant = ({ screenShareParticipant, callInfo, handleSelectScreenShareParticipant }) => {

    const { user } = useAuthService();

    const divRef = useRef(null);
    const videoRef = useRef(null);

    const [vTrack, setVTrack] = useState(null);
    const [participantInfoFromCall, setParticipantInfoFromCall] = useState(null);

    const participant = screenShareParticipant.participant
    let tracks = participant.getTrackPublications();

    const getParticipantInfoFromCall = () => {
        let info;
        let foundInOthers = callInfo.other_participants.find(
            (p) => p.id == participant.identity
        );

        if (participant.identity == callInfo.created_by.id) {
            info = callInfo.created_by;
        } else if (foundInOthers) {
            info = foundInOthers;
        } else {
            info = user;
        }

        if (info) {
            setParticipantInfoFromCall(info);
        }
    };



    useEffect(() => {
        let videoTrack = tracks.find((track) => track.source === "screen_share" && track.track != null);
        if (videoTrack && videoTrack?.track) {
            setVTrack(videoTrack);
            videoTrack.track.attach(videoRef.current);
        } else {
            setVTrack(null);
        }
    }, [tracks]);

    useState(() => {
        getParticipantInfoFromCall();
    }, [participant]);



    return (
        <ConnectingContainer ref={divRef} onClick={() => handleSelectScreenShareParticipant(screenShareParticipant)}>
            <VideoContainer>
                <video ref={videoRef} autoPlay muted playsInline />
            </VideoContainer>
        </ConnectingContainer>
    );
};

export default ScreenShareParticipant;
const ConnectingContainer = styled.div`
  display: flex;
  flex:0 0 calc(25% - 10px);
  min-height:150px;
  min-width:250px;
  max-height:150px;
border:1px solid ${colors.listColor};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.callComponent.darkColor};
  overflow: hidden;
  border-radius: 10px;
  cursor:pointer;
`;

const VideoContainer = styled.div`
 width: 100%; 
  height: 100%; 
  position: relative;
  overflow: hidden; 
  display: flex; 
  align-items: center;
  justify-content: center;
video {
 width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: center;
  }
`