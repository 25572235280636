import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";
import "../../Assets/styles/customPhoneSelect.css";
import "react-phone-number-input/style.css";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as AvatarUnset } from "../../Assets/icons/unsetAvatar.svg";
import { ReactComponent as LogoutIcon } from "../../Assets/icons/logoutIcon.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/icons/deleteIcon.svg";
import { ReactComponent as RightArrow } from "../../Assets/icons/rightArrow.svg";
import { ReactComponent as BackIcon } from "../../Assets/icons/arrowBack.svg";
import { ReactComponent as AvatarPencil } from "../../Assets/icons/avatarPencil.svg";
import { useAuthService } from "../../Context/AuthContext";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";
import BirthdayMenu from "../Auth Components/BirthdayMenu";
import SingleOptionMenu from "../Auth Components/SingleOtionMenu";
import { genderList, languageList } from "../../Constants/DropdownValues";
import { localizationStrings } from "../../Localization/Localization";
import SingleOptionLanguageMenu from "../Auth Components/SingleOptionLanguageMenu";
import DeleteConfirmationChild from "../GenericComponents/DeleteConfirmationChild";
import LocalStorage from "../../Helpers/LocalStorage";
import SingleOptionCallLanguageMenu from "./SingleOptionCallLanguageMenu";

const ProfileOptions = ({
  handleInputChange,
  inputValues,
  birthdate,
  setBirthdate,
  gender,
  setGender,
  language,
  setLanguage,
  infoChanged,
  setIsProfileOpen,
  handleUpdateUser,
  initializeInputValue,
  handleOpenBlockUsers,
  handleOpenSubscriptionModal,
  setIsDeleteReasonModalOpen,
  setDeleteConfirmationOpen,
  deleteConfirmationOpen,
  selectedReasons,
  callLanguage,
  setCallLanguage,
  setLoading,
  handleOpenReferralModal,
}) => {
  const {
    singOutFirebase,
    user,
    deleteUser,
    updateUserProfilePictures,
    translationsLanguages,
    handleSignOutUser,
    callTranslationsLanguages,
  } = useAuthService();

  const [phone, setPhone] = useState(user?.phone_number);

  const profileAvatarRef = useRef();
  const appVersion = "2.0";

  const handleLogout = () => {
    if (LocalStorage.getSSOFlag() === "true") {
      handleSignOutUser();
    } else {
      singOutFirebase();
    }
  };

  const handleOpenAvatar = () => {
    profileAvatarRef.current.click();
  };

  const handleCloseProfileBtn = () => {
    initializeInputValue();
    setIsProfileOpen(false);
  };

  const handleAvatarChange = (e) => {
    const image = e.target.files[0];

    const data = new FormData();
    data.append("image", image);

    updateUserProfilePictures(
      data,
      function (response) {
        notifySuccess(localizationStrings.profile.profile_picture_changed);
      },
      function (error) {
        notifyError(`${localizationStrings.profile.something_wrong}`);
        console.log(error);
      }
    );
  };

  const handleDeleteAccount = () => {
    let reasons = selectedReasons.map((item) => {
      return item.reason;
    });
    setLoading(true);
    deleteUser(
      reasons,
      (response) => {
        setLoading(false);
      },
      (error) => {
        notifyError(`${localizationStrings.profile.something_wrong}`);
        console.log(error);
        setLoading(false);
      }
    );
  };

  function auto_grow(element) {
    element.style.height = "5px";
    element.style.height = element.scrollHeight + "px";
  }

  const navigateToLandingPageLink = (to) => {
    let url = "http://emotii.ai";
    window.open(`${url}/${to}`);
  };

  const handleSupportClick = (e) => {
    e.preventDefault();
    window.location = "mailto:support@emotii.ai";
  };

  useEffect(() => {
    let element = document.getElementById("resizeTa");
    let element2 = document.getElementById("resizeTa-2");

    auto_grow(element);
    auto_grow(element2);
  }, [inputValues]);

  const openDeleteReasonModal = () => {
    setIsDeleteReasonModalOpen(true);
  };

  return (
    <ProfileOptionsContainer>
      <Container>
        <BackButton onClick={() => handleCloseProfileBtn()}>
          <BackIcon />
          {localizationStrings.profile.back}
        </BackButton>
        <ProfileTitle>
          <h1>{localizationStrings.profile.my_profile}</h1>
        </ProfileTitle>
        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
          {infoChanged && (
            <SaveButton onClick={() => handleUpdateUser()}>Save</SaveButton>
          )}
        </div>
      </Container>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.profile.personal_details}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ProfileDetails>
            <UserWrapper>
              <input
                type="file"
                hidden
                accept=".png, .jpg, .jpeg "
                ref={profileAvatarRef}
                onChange={(e) => handleAvatarChange(e)}
              />
              <UserAvatarWrapper>
                <UserAvatar onClick={() => handleOpenAvatar()}>
                  {user.profile_picture ? (
                    <img src={user.profile_picture} alt="avatar" />
                  ) : (
                    <AvatarUnset />
                  )}
                </UserAvatar>
                <PencilWrapper>
                  <AvatarPencil />
                </PencilWrapper>
              </UserAvatarWrapper>
              <CustomNameInput
                value={inputValues.name}
                onChange={(e) => handleInputChange(e)}
                name="name"
                maxLength={100}
              ></CustomNameInput>
            </UserWrapper>
            <Status>
              <CustomTextArea
                id="resizeTa-2"
                placeholder={localizationStrings.profile.type_your_status}
                value={inputValues.about}
                onChange={(e) => handleInputChange(e)}
                name="about"
                maxLength={280}
              />
            </Status>
          </ProfileDetails>
        </OptionWrapper>
      </Group>
      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.profile.chat_settings}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ChatOption style={{ alignItems: "center" }}>
            <h1>{localizationStrings.profile.language}</h1>
            <SingleOptionLanguageMenu
              slim={true}
              value={language}
              setValue={setLanguage}
              valuesList={translationsLanguages}
              placeholder={localizationStrings.profile.please_select_language}
            />
          </ChatOption>
          <ChatOption style={{ alignItems: "center" }}>
            <h1>{localizationStrings.profile.birthday}</h1>
            <BirthdayMenu
              slim={true}
              format={"yyyy-dd-MM"}
              value={birthdate}
              setValue={setBirthdate}
            />
          </ChatOption>
          <ChatOption>
            <h1>{localizationStrings.profile.gender}</h1>
            <SingleOptionMenu
              slim={true}
              value={gender}
              setValue={setGender}
              valuesList={genderList}
              placeholder={localizationStrings.profile.please_select_gender}
            />
          </ChatOption>
          <ChatOption style={{ flexDirection: "column" }}>
            <h1>{localizationStrings.profile.cultural_background}</h1>
            <CustomTextArea
              id="resizeTa"
              placeholder={localizationStrings.profile.type_cultural_background}
              value={inputValues.cultural_background}
              onChange={(e) => handleInputChange(e)}
              name="cultural_background"
              maxLength={1000}
            ></CustomTextArea>
          </ChatOption>
        </OptionWrapper>
      </Group>
      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.profile.call_settings}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ChatOption style={{ alignItems: "center" }}>
            <h1>{localizationStrings.profile.language}</h1>
            <SingleOptionCallLanguageMenu
              slim={true}
              value={callLanguage}
              setValue={setCallLanguage}
              valuesList={callTranslationsLanguages}
              placeholder={localizationStrings.profile.please_select_language}
            />
          </ChatOption>
        </OptionWrapper>
      </Group>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.profile.account_details}</h1>
        </TitleWrapper>
        <OptionWrapper>
          {user.phone_number && (
            <ChatOption style={{ flexDirection: "column" }}>
              <h1>{localizationStrings.profile.phone_number}</h1>
              <PhoneNumberWrapper>
                <CustomPhoneWrapper>
                  <PhoneInput
                    readOnly
                    international
                    defaultCountry={"RO"}
                    name="phoneNumber"
                    placeholder={localizationStrings.profile.phone_number}
                    value={phone}
                    onChange={(e) => setPhone(e)}
                    limitMaxLength
                  />
                </CustomPhoneWrapper>
              </PhoneNumberWrapper>
            </ChatOption>
          )}
          {user.email && (
            <ChatOption style={{ flexDirection: "column" }}>
              <h1>{localizationStrings.profile.email}</h1>
              <ChatOptionButton style={{ padding: "10px 0 5px 0" }}>
                {user.email}
              </ChatOptionButton>
            </ChatOption>
          )}

          <ChatOption>
            <ChatOptionButton onClick={(e) => handleOpenSubscriptionModal()}>
              {localizationStrings.profile.subscription}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
          <ChatOption>
            <LogoutButton onClick={() => handleLogout()}>
              {localizationStrings.profile.logout}
              <LogoutIcon />
            </LogoutButton>
          </ChatOption>
        </OptionWrapper>
      </Group>

      <Group>
        <TitleWrapper>
          <h1>{localizationStrings.profile.others}</h1>
        </TitleWrapper>
        <OptionWrapper>
          <ChatOption>
            <ChatOptionButton onClick={(e) => handleOpenBlockUsers()}>
              {localizationStrings.profile.block_users}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
          <ChatOption>
            <ChatOptionButton onClick={(e) => handleOpenReferralModal()}>
              {localizationStrings.profile.invite_new_contact}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
          <ChatOption>
            <ChatOptionButton
              onClick={(e) => navigateToLandingPageLink("terms")}
            >
              {localizationStrings.profile.term_and_conditions}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
          <ChatOption>
            <ChatOptionButton
              onClick={(e) => navigateToLandingPageLink("privacy")}
            >
              {localizationStrings.profile.privacy}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
          <ChatOption onClick={(e) => handleSupportClick(e)}>
            <ChatOptionButton>
              {localizationStrings.profile.support}
              <RightArrow />
            </ChatOptionButton>
          </ChatOption>
        </OptionWrapper>
      </Group>
      <Group>
        <Group>
          <TitleWrapper>
            <h1>{localizationStrings.profile.account_closure}</h1>
          </TitleWrapper>
          <OptionWrapper>
            <ChatOption>
              <DeleteConfirmationChild
                open={deleteConfirmationOpen}
                setOpen={setDeleteConfirmationOpen}
                variant={"remove_account"}
                action={handleDeleteAccount}
              >
                <DeleteAccountButton onClick={() => openDeleteReasonModal()}>
                  {localizationStrings.profile.delete_account}
                  <DeleteIcon />
                </DeleteAccountButton>
              </DeleteConfirmationChild>
            </ChatOption>
          </OptionWrapper>
        </Group>
        <TitleWrapper>
          <h1>
            {" "}
            {localizationStrings.profile.version} {appVersion}
          </h1>
        </TitleWrapper>
      </Group>
    </ProfileOptionsContainer>
  );
};

export default ProfileOptions;
const ProfileOptionsContainer = styled.div`
  background: ${colors.newChatModalBg};
  display: flex;
  flex-direction: column;
  padding: 24px 21px;
  gap: 25px;
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  overflow: auto;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  h1 {
    color: ${colors.optionsGroupTitle};
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
  }
`;
const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  background: ${colors.newChatModalInputBg};
  border-radius: 10px;
`;
const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 42px;
  max-width: 42px;
  min-width: 42px;
  min-height: 42px;
  object-fit: contain;
  position: relative;
  overflow: hidden;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;
const UserWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #363e5180;
  padding: 8px 0;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    color: ${colors.white};
  }
`;
const Status = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;
  p {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
`;
const ChatOption = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 13px 0;
  border-bottom: 1px solid #363e5180;

  &:last-child {
    border: none;
  }
  h1 {
    min-width: 75px;
    color: #ffffff99;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
  h2 {
    color: ${colors.white};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.40799999237060547px;
  }
`;
const PhoneNumberWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CustomPhoneWrapper = styled.div`
  background: ${colors.newChatModalInputBg};
  //padding:0 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  input {
    padding: 14px 16px;
    color: ${colors.white};
    background: ${colors.newChatModalInputBg};
    border-radius: 10px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
`;

const LogoutButton = styled.button`
  display: flex;
  justify-content: space-between;
  border: none;
  background: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  cursor: pointer;
  svg {
    color: #67696e;
  }
`;
const DeleteAccountButton = styled.button`
  display: flex;
  justify-content: space-between;
  border: none;
  background: none;
  color: ${colors.errorColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  width: 100%;
  cursor: pointer;
`;
const ChatOptionButton = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  border: none;
  background: none;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  gap: 10px;
`;

const CustomInput = styled.input`
  width: 100%;
  background: none;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;
const CustomTextArea = styled.textarea`
  width: 100%;
  resize: none;
  background: none;
  min-height: 30px;
  overflow: hidden;
  border: none;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.40799999237060547px;
  &:focus-visible {
    outline: none;
  }
`;
const CustomNameInput = styled(CustomInput)`
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
`;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${colors.newChatModalBg};
  min-height: 38px;
`;
const BackButton = styled.button`
  width: 100%;
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  gap: 10px;
  display: flex;
  align-items: center;
`;
const ProfileTitle = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  h1 {
    color: ${colors.white};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
`;
const SaveButton = styled.button`
  cursor: pointer;
  color: ${colors.white};
  background: ${colors.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
`;

const PencilWrapper = styled.div`
  position: absolute;
  bottom: -5px;
  right: 0;
`;
const UserAvatarWrapper = styled.div`
  position: relative;
`;
