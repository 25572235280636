import React from "react";
import styled from "styled-components";
import ControlButton from "./ControlButton";
import { localizationStrings } from "../../Localization/Localization";
import { ReactComponent as MicorphoneActive } from "../../Assets/icons/callControlButtons/microphone-active.svg";
import { ReactComponent as MicorphoneInactive } from "../../Assets/icons/callControlButtons/microphone-inactive.svg";
import { ReactComponent as VideoActive } from "../../Assets/icons/callControlButtons/camera-active.svg";
import { ReactComponent as VideoInactive } from "../../Assets/icons/callControlButtons/camera-inactive.svg";
import { ReactComponent as TranslateActive } from "../../Assets/icons/callControlButtons/translate-active.svg";
import { ReactComponent as TranslateInactive } from "../../Assets/icons/callControlButtons/translate-inactive.svg";
import { ReactComponent as ShareScreenActive } from "../../Assets/icons/callControlButtons/share-screen-active.svg";
import { ReactComponent as ShareScreenInactive } from "../../Assets/icons/callControlButtons/share-screen-inactive.svg";
import { ReactComponent as Participants } from "../../Assets/icons/callControlButtons/add-participants.svg";
import { ReactComponent as ParticipantsOpen } from "../../Assets/icons/callControlButtons/add-participants-open.svg";
import { ReactComponent as CloseCall } from "../../Assets/icons/callControlButtons/closeCall.svg";

import { colors } from "../../Assets/styles/colors";
import { isSubscriptionLevelEnoughForAccessLevel } from "../../Helpers/subscriptions/SubscriptionsHelper";
import { ACCESS_LEVEL_PLATFORM } from "../../Constants/constantValues";
import { useAuthService } from "../../Context/AuthContext";

const ControlBar = ({
  handleMuteBtn,
  handleVideoBtn,
  handleShareScreenBtn,
  hansleParticipantsBtn,
  handleCloseCallBtn,
  handleTranslationBtn,
  videoEnabled,
  micEnabled,
  isParticipantsOpen,
  translationEnabled,
  screenSharing
}) => {
  const { subscriptionLevel } = useAuthService();

  return (
    <ControlsContainer>
      {!translationEnabled && (
        <ControlButton
          active={micEnabled}
          activeIcon={<MicorphoneActive />}
          inactiveIcon={<MicorphoneInactive />}
          label={localizationStrings.call_modal.mute}
          onClick={handleMuteBtn}
        />
      )}
      <ControlButton
        active={videoEnabled}
        activeIcon={<VideoActive />}
        inactiveIcon={
          <VideoInactive />
        }
        label={localizationStrings.call_modal.video}
        onClick={handleVideoBtn}
      />

      <ControlButton
        active={translationEnabled}
        activeIcon={<TranslateActive />}
        inactiveIcon={<TranslateInactive />}
        label={localizationStrings.call_modal.translate}
        onClick={handleTranslationBtn}
      />

      {isSubscriptionLevelEnoughForAccessLevel(subscriptionLevel, ACCESS_LEVEL_PLATFORM) &&
        <ControlButton
          active={screenSharing}
          activeIcon={<ShareScreenActive />}
          inactiveIcon={<ShareScreenInactive />}
          label={localizationStrings.call_modal.share_screen}
          onClick={handleShareScreenBtn}
        />
      }
      <ControlButton
        active={isParticipantsOpen}
        activeIcon={<ParticipantsOpen />}
        inactiveIcon={<Participants />}
        label={localizationStrings.call_modal.participants}
        onClick={hansleParticipantsBtn}
      />

      <ControlButton
        active={true}
        activeIcon={<CloseCall />}
        onClick={handleCloseCallBtn}
      />
    </ControlsContainer>
  );
};

export default ControlBar;

const ControlsContainer = styled.div`
  display: flex;
  align-items: top;
  justify-content: center;
  width: 100%;
  padding-top: 8px;
  height: 80px;
  background: ${colors.callControlBar.bg};
  border-top: 1px solid ${colors.callControlBar.topBorderColor};
  gap: 25px;
  margin-top: auto;
`;
