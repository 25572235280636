import { DELETE, GET, POST, POST_BLOB } from "../API";

let source;

export const createGroupCallApiCall = (
  user_ids,
  group_id,
  video,
  onSuccess,
  onError
) => {
  const data = {
    user_ids: user_ids,
    group_id: group_id,
    video: video,
  };
  POST(
    `/api/v1/chat/calls/create_with_users_and_group`,
    data,
    onSuccess,
    onError
  );
};

export const addCallParticipantAiCall = (
  user_ids,
  call_id,
  onSuccess,
  onError
) => {
  const data = {
    user_ids: user_ids,
    call_id: call_id,
  };
  POST(`/api/v1/chat/calls/add_participants_to_call`, data, onSuccess, onError);
};
export const callOfflineParticipantAiCall = (
  user_id,
  call_id,
  onSuccess,
  onError
) => {
  const data = {
    user_id: user_id,
    call_id: call_id,
  };
  POST(`/api/v1/chat/calls/ring_participant`, data, onSuccess, onError);
};

export const getCallDetailsApiCall = (uuid, onSuccess, onError) => {
  const data = {
    uuid: uuid,
  };
  POST(`/api/v1/chat/calls`, data, onSuccess, onError);
};

export const getCallsForCurrentUserApiCall = (onSuccess, onError) => {
  source = GET(`/api/v1/chat/calls`, onSuccess, onError, true, source);
};

export const deleteCallAPICall = (callId, onSuccess, onError) => {
  DELETE(
    `/api/v1/chat/calls/delete?uuid=${callId}`,
    onSuccess,
    onError,
    true,
    source
  );
};

export const transcribeAPICall = (formData, onSuccess, onError) => {
  POST(`/api/v1/chat/audio/transcribe`, formData, onSuccess, onError);
};

export const speakAudioAPICall = (text, onSuccess, onError) => {
  const data = {
    text: text,
  };
  POST_BLOB(
    `/api/v1/chat/audio/speak`,
    data,
    onSuccess,
    onError,
    true,
    null,
    true
  );
};
