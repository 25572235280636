import React, { Suspense, useState } from 'react'
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import DeleteConfirmationChild from '../GenericComponents/DeleteConfirmationChild';
import { localizationStrings } from '../../Localization/Localization';

const BlockContactElement = ({ item, blocked, blockContact, unblockContact }) => {
  const [openUnblockModal, setOpenUnblockModal] = useState(false)
  const [openBlockModal, setOpenBlockModal] = useState(false)

  return (
    <Warpper >
      <FoundContact >
        <FoundContactAvatar>
          {item.profile_picture ? <img src={item.profile_picture} alt='avatar' /> : <AvatarUnset />}
        </FoundContactAvatar>
        <p>{item.name}</p>
        {blocked ?
          <DeleteConfirmationChild
            open={openUnblockModal}
            setOpen={setOpenUnblockModal}
            variant={"unblock_contact"}
            action={unblockContact}
            item={item}

          >
            <TextButton onClick={() => setOpenUnblockModal(true)}>
              Unblock
            </TextButton>
          </DeleteConfirmationChild>

          :
          <DeleteConfirmationChild
            open={openBlockModal}
            setOpen={setOpenBlockModal}
            variant={"block_contact"}
            action={blockContact}
            item={item}

          >
            <TextButton onClick={() => setOpenBlockModal(true)}>
              {localizationStrings.block_contacts.block}
            </TextButton>
          </DeleteConfirmationChild>

        }
      </FoundContact>
    </Warpper>

  )
}

export default BlockContactElement
const Warpper = styled.div`
display:block;
z-index:1;
`
const FoundContact = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  cursor:pointer;
  
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${colors.white};
    width: 100%;
  }
`;
const FoundContactAvatar = styled.div`
  display: flex;
  height:45px;
  width:45px;
  max-width: 45px;
  min-height: 45px;
  min-width: 45px;
  max-height: 45px;
  overflow: hidden;
  border-radius: 100%;
  // height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
    object-fit: cover;
   width: 100%;
   height: 100%;
 }
`;


const TextButton = styled.button`
border:none;
background:none;
font-size: 16px;
font-weight: 400;
line-height: 22px;
color:${colors.errorColor};
cursor:pointer;
`