import { useEffect, useRef, useState } from "react";
import { useCallsService } from "../../Context/CallsContext";
import { cloneDeep } from "lodash";

const TranslatedMessageSpeaker = ({
  speakMessagesList,
  setShouldWaitForSpeak,
}) => {
  const { speakAudio } = useCallsService();
  const initialListCountRef = useRef(0);

  const [audioFiles, setAudioFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [audioCounter, setAudioCounter] = useState(0);

  const audioFilesRef = useRef([]);

  const handleSpeak = (text) => {
    speakAudio(
      text,
      (response) => {
        const apiResponse = response.data;
        const url = URL.createObjectURL(apiResponse);
        audioFilesRef.current.push(url);
        setAudioFiles(audioFilesRef.current);
        setAudioCounter(audioCounter + 1);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleEnd = () => {
    if (currentIndex < audioFiles.length) {
      let newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };

  const handlePlay = (audioFile) => {
    if (!audioFile) {
      setShouldWaitForSpeak(false);
      return;
    }
    const audio = new Audio(audioFile);
    audio.play();
    audio.onended = () => {
      handleEnd();
      setShouldWaitForSpeak(false);
    };
  };

  useEffect(() => {
    if (speakMessagesList.length == 0) {
      return;
    }
    let speakMessakeListCopy = cloneDeep(speakMessagesList);
    let speakItem = speakMessakeListCopy[0];
    if (speakItem) {
      if (initialListCountRef.current !== speakItem.call_message_id) {
        handleSpeak(speakItem.translation);
      }

      initialListCountRef.current = speakItem.call_message_id;
    }
  }, [speakMessagesList]);

  useEffect(() => {
    if (audioCounter > 0 && currentIndex < audioCounter) {
      handlePlay(audioFiles[currentIndex]);
    }
  }, [currentIndex, audioCounter]);
};

export default TranslatedMessageSpeaker;
