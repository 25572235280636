import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { localizationStrings } from "../../Localization/Localization";
import { useAuthService } from "../../Context/AuthContext";
import TimeAgo from "timeago-react";
import { displayAvatar } from "../../Helpers/chatHelper/AvatarHelper";
import { displayName } from "../../Helpers/chatHelper/NameHelper";
import { ReactComponent as OutgoingIcon } from "../../Assets/icons/OutgoingCallIcon.svg";
import { ReactComponent as IncomingIcon } from "../../Assets/icons/IconmingCallIcon.svg";
import { ReactComponent as CallIcon } from "../../Assets/icons/missed-call.svg";
import moment from "moment";

const CallListElement = ({ handleSelectCall, data, selected, displayMenu }) => {
  const { user } = useAuthService();

  const timeAgoStyle = {
    color: colors.chatElementColor,
    fontWeight: "400",
    fontSize: "14px",
    marginLeft: "auto",
  };

  const displayNewOrTimeAgo = (data) => {
    const created_at = moment(data.created_at);
    const nowDate = moment();
    let daysBefore = created_at.diff(nowDate, "days");

    if (daysBefore === 0) {
      return created_at.format("HH:mm");
    }

    if (0 > daysBefore > -7) {
      return created_at.format("dddd");
    }

    if (-7 > daysBefore) {
      return created_at.format("DD-MM-YYYY");
    }
  };

  const verifyCallStatus = (call) => {
    if (!call) {
      return;
    }

    let status = "ongoing";

    if (call.close_date === null) {
      status = "ongoing";
    } else if (call.created_by.id === user.id) {
      status = "outgoing";
    } else if (call.missed) {
      status = "missed";
    } else {
      status = "incoming";
    }
    return status;
  };

  const isVideoCall = (call) => {
    let displayVideoText = <div></div>;
    if (call.video) {
      displayVideoText = (
        <CallDirection status={verifyCallStatus(data)}>
          <p>({localizationStrings.call_modal.video_call})</p>
        </CallDirection>
      );
    }

    return displayVideoText;
  };
  const callStatusAndDirection = (call) => {
    let displayDirection = <div></div>;
    let status = verifyCallStatus(call);

    switch (status) {
      case "ongoing":
        displayDirection = (
          <CallDirection status={verifyCallStatus(data)}>
            <OutgoingIcon />
            <p>
              {localizationStrings.call_status.ongoing} (
              {localizationStrings.call_status.click_join})
            </p>
          </CallDirection>
        );
        break;
      case "outgoing":
        displayDirection = (
          <CallDirection>
            <OutgoingIcon />
            <p> {localizationStrings.call_status.outgoing}</p>
          </CallDirection>
        );
        break;
      case "incoming":
        displayDirection = (
          <CallDirection>
            <IncomingIcon />
            <p>{localizationStrings.call_status.incoming}</p>
          </CallDirection>
        );
        break;
      case "missed":
        displayDirection = (
          <CallDirection status={verifyCallStatus(data)}>
            <CallIcon color={colors.errorColor} />
            <p>{localizationStrings.call_status.missed}</p>
          </CallDirection>
        );
        break;

      default:
        break;
    }
    return displayDirection;
  };

  return (
    <CallListElementContainer
      onClick={() => handleSelectCall(data, data.video)}
      $selected={selected}
      onContextMenu={(e) => displayMenu(e, data)}
    >
      <UserAvatar style={data.quick_chat ? { border: "none" } : {}}>
        {displayAvatar(data)}
      </UserAvatar>
      <Text>
        <PhoneNumber
          $missed={verifyCallStatus(data)}
          ongoing={verifyCallStatus(data)}
        >
          <h1>{displayName(data)}</h1>
        </PhoneNumber>
        <Message status={verifyCallStatus(data)}>
          {callStatusAndDirection(data)}
          {isVideoCall(data)}
          <h3> {displayNewOrTimeAgo(data)}</h3>
        </Message>
      </Text>
    </CallListElementContainer>
  );
};

export default CallListElement;
const CallListElementContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  background: ${(props) =>
    props.$selected ? colors.chatElementBgSelected : colors.chatElementBg};
  border-radius: 16px;
  gap: 16px;
  border: ${(props) => (props.$selected ? "1px solid #5852D633" : "")};
  margin: ${(props) => (props.$selected ? "-1px" : "")};
`;
const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  cursor: pointer;
  max-height: 45px;
  max-width: 45px;
  object-fit: contain;
  position: relative;
  min-width: 45px;
  min-height: 45px;
  overflow: hidden;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: cover;
    position: relative;
    height: 43px;
    width: 43px;
  }
`;
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: hidden;
  width: 100%;
`;
const PhoneNumber = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    color: ${(props) =>
      props.$missed === "missed" ? colors.errorColor : colors.white};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    color: ${colors.purple};
  }

  ${(props) =>
    props.ongoing === "ongoing" &&
    `
h1{
color:${colors.purple};
}

`}
`;
const Message = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
  p {
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${colors.chatElementColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  h3 {
    margin-left: auto;
    color: ${colors.chatElementColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  ${(props) =>
    props.status === "ongoing" &&
    `
    h3{
    color:${colors.purple};
    }
    `}
  ${(props) =>
    props.status === "missed" &&
    `
    h3{
    color:${colors.errorColor};
    }
    `}
`;

const CallDirection = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: ${colors.chatElementColor};
  svg {
    min-height: 16px;
    min-width: 16px;
    max-width: 16px;
    max-height: 16px;
  }
  p {
    font-size: 14px;
    font-eight: 400;
    line-height: 16px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: wrap;
    text-overflow: ellipsis;
    color: ${colors.chatElementColor};
    text-align: left;
  }

  ${(props) =>
    props.status === "ongoing" &&
    `
    color:${colors.purple};
    p{
    color:${colors.purple};
    }
    `}
  ${(props) =>
    props.status === "missed" &&
    `
      color:${colors.errorColor};
    p{
    color:${colors.errorColor};
    }
    `}
`;
