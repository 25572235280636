import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as ProBadge } from '../../Assets/icons/subscription/crown.svg'
import { ReactComponent as FreeBadge } from '../../Assets/icons/subscription/free.svg'
import { colors } from '../../Assets/styles/colors'
const SelectableParticipant = ({ handleClick, participant, selected }) => {

  let displayName = participant?.name

  if (participant?.name === "") {
    displayName = participant?.phone_number
  }


  return (
    <Warpper onClick={handleClick}>
      <FoundContact  >
        <Avatar>
          {participant?.profile_picture ? <img src={participant.profile_picture} alt='avatar' /> : <AvatarUnset />}
        </Avatar>
        <ContactNameWrapper>
          <Name>{displayName}</Name>

          <CustomCheckBox >
            <label className="container">
              <input
                type="checkbox"
                checked={selected}
                readOnly
              />
              <span
                className="checkmark">
              </span>
            </label>
          </CustomCheckBox>
        </ContactNameWrapper>
      </FoundContact>
    </Warpper>
  )
}

export default SelectableParticipant
const Warpper = styled.div`
z-index:1;
display:flex;
align-items:center;
cursor:pointer;
`
const FoundContact = styled.div`
 display:flex;
align-items:center;
gap:10px;
padding: 0 16px;
height:40px;
min-height:40px;
max-height:40px;
width:100%;
`;

const Avatar = styled.div`
  display: flex;
   height:35px;
  width:35px;
    max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
    img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

const CustomCheckBox = styled.div`
display:block;
margin-left:auto;
z-index:-1;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index:0;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid rgba(91, 91, 93, 1);
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: rgba(112, 105, 253, 1);
    border-color:rgba(112, 105, 253, 1);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 6.5px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const ProBadgeWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
bottom:-5px;
right:-3px;
`

const ContactNameWrapper = styled.div`
display:flex;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.2);
width:100%;
overflow:hidden;
gap:10px;
height:100%;
`
const Name = styled.p`
font-size: 16px;
font-weight: 400;
line-height: 20px;
color:${colors.white};
`
