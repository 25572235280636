import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import ControlBar from "./ControlBar";
import ConnectingView from "./ConnectingView";
import ParticipantComponent from "./ParticipantComponent";
import SpeakingTranslator from "./SpeakingTranslator";
import CallHeader from "./CallHeader";
import Participants from "./Participants";
import { useWebSocketDataService } from "../../Context/WebsocketContext";
import {
  RoomAudioRenderer,
  useLocalParticipant,
  useRemoteParticipants,
  useRoomContext,
  useTracks,
} from "@livekit/components-react";
import RecorderService from "./RecorderService";
import { Howl } from "howler";
import callingSound from "../../Assets/sounds/ring.wav";
import connectedSound from "../../Assets/sounds/connected.mp3";
import beepSound from "../../Assets/sounds/beep.wav";
import ParticipantsWrapper from "./ParticipantsWrapper";
import { createLocalVideoTrack, Track } from "livekit-client";
import SmallOwnCameraComponent from "./SmallOwnCameraComponent";
import { useAuthService } from "../../Context/AuthContext";
import ParticipantsWrapperTranslationGrid from "./ParticipantsWrapperTranslationGrid";
import { notifyError } from "../../Helpers/Notifications";
import { localizationStrings } from "../../Localization/Localization";
import ScreenSharingGrid from "./ScreenSharingGrid";
import ShareScreenPicker from "../../electron/ShareScreenPicker";
import { LocalVideoTrack } from "livekit-client";
import toast from "react-hot-toast";

export const beepSoundHowl = new Howl({
  src: [beepSound],
  loop: false,
});

export const callingSoundHowl = new Howl({
  src: [callingSound],
  loop: true,
});

export const connectedSoundHowl = new Howl({
  src: [connectedSound],
});

const CallView = ({
  data,
  callAccess,
  resetCall,
  speechRecongnitionMessages,
  translationEnabled,
  setTranslationEnabled,
  translationEnabledRef,
  handleSendRequestTranslationMessage,
  translationDisabledManuallyRef,
  handleSendCancelSpeakMessage,
  handleSendDenyTranslationsMessage,
  handleTranscribeAudio,
  speakMessagesList,
  setLoading,
  setSelectedCall,
  activeCallRef,
  handleRequestSpeakMessage,
  speakersQueue,
}) => {
  const ONECALL = "single";
  const GROUPCALL = "group";

  const ENDED = "ended";
  const REQUESTED = "requested";
  const SPEAKING = "speaking";

  const { user } = useAuthService();

  const { sendCloseCallMessage } = useWebSocketDataService();
  const room = useRoomContext();
  const containerRef = useRef(null);
  const remoteParticipant = useRemoteParticipants();
  const localParticipant = useLocalParticipant();

  const [micEnabled, setMicEnabled] = useState(
    !room.localParticipant.isMicrophoneEnabled
  );
  const micEnabledRef = useRef(true);
  const [videoEnabled, setVideoEnabled] = useState(data.video);

  const [grid, setGrid] = useState(false);
  const [callType, setCallType] = useState("");
  const [isParticipantsOpen, setIsParticipantsOpen] = useState(false);
  const [callParticipants, setCallParticipants] = useState([]);
  const [previousSpeakStatus, setPreviousSpeakStatus] = useState(null);
  const [speakStatus, setSpeakStatus] = useState(ENDED);
  const [screenSharing, setScreenSharing] = useState(false);
  const [isPickingShareScreen, setIsPickingShareScreen] = useState(false);

  const publishedTracks = useTracks();
  const [participantIsSharingScreen, setParticipantIsSharingScreen] =
    useState(null);
  const [screenSharingParticipantsList, setScreenSharingParticipantsList] =
    useState(null);

  const handleMuteBtn = async () => {
    let microphone = localParticipant.localParticipant.getTrackPublication(
      Track.Source.Microphone
    );
    if (microphone && micEnabled) {
      muteMicrophone();
    } else {
      unmuteMicrophone();
    }
  };

  const muteMicrophone = async () => {
    let microphone = localParticipant.localParticipant.getTrackPublication(
      Track.Source.Microphone
    );
    await microphone.track.mute();
    micEnabledRef.current = false;
    setMicEnabled(false);
  };

  const unmuteMicrophone = async () => {
    let microphone = localParticipant.localParticipant.getTrackPublication(
      Track.Source.Microphone
    );
    await microphone.track.unmute();
    micEnabledRef.current = true;
    setMicEnabled(true);
  };

  const disableMicrophone = async () => {
    try {
      // Get the user's microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      // Stop all audio tracks
      stream.getAudioTracks().forEach((track) => {
        track.stop(); // This stops the microphone completely
      });


    } catch (err) {
      console.error("Error disabling microphone:", err);
    }
  };

  const handleDisconect = async (e) => {
    if (room?.state === "connecting") {
      return;
    }
    await disableMicrophone();
    e?.preventDefault();
    room.disconnect();
    sendCloseCallMessage(data.uuid);
    handleSendCancelSpeakMessage();
    callingSoundHowl.stop();
    translationEnabledRef.current = false
    resetCall();
  };

  const publishVideoTrack = async () => {
    try {
      const permission = await checkCameraPermission();

      if (permission === "granted") {
        let videoTrackPublication =
          localParticipant.localParticipant.getTrackPublication(
            Track.Source.Camera
          );

        if (!videoTrackPublication) {
          // Create and publish new video track
          const track = await createLocalVideoTrack();
          await localParticipant.localParticipant.publishTrack(track);
          setVideoEnabled(true);
        } else {
          // Re-publish existing video track
          await localParticipant.localParticipant.publishTrack(
            videoTrackPublication.track
          );
          setVideoEnabled(true);
        }
      } else if (permission === "denied") {
        notifyError(localizationStrings.misc.video_access);
      }
    } catch (error) {
      notifyError(localizationStrings.misc.video_access);
    }
  };

  const checkCameraPermission = async () => {
    try {
      if (!navigator.permissions) {
        throw new Error("Permissions API not supported");
      }
      const result = await navigator.permissions.query({ name: "camera" });

      if (result.state === "prompt") {
        return requestCameraPermission();
      } else {
        return result.state;
      }
    } catch (err) {
      console.error("Error checking camera permissions:", err);
      return;
    }
  };

  const requestCameraPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach((track) => track.stop()); // Release the camera
      return "granted";
    } catch (err) {
      console.error("Error requesting camera permissions:", err.message);
      return "denied";
    }
  };

  const handleUnpublishVideo = async () => {
    let videoTrackPublication =
      localParticipant.localParticipant.getTrackPublication(
        Track.Source.Camera
      );
    if (localParticipant && videoTrackPublication && videoEnabled) {
      await localParticipant.localParticipant.unpublishTrack(
        videoTrackPublication.track
      );
      setVideoEnabled(false);
    }
  };

  const mapCallParticipants = (call) => {
    const uniqueIds = new Set();
    uniqueIds.add(call.created_by.id);
    call.other_participants.forEach((item) => uniqueIds.add(item.id));
    uniqueIds.add(user.id);
    const combined = [user, call.created_by, ...call.other_participants];
    const seenIds = new Set();
    const filteredObjects = combined.filter((obj) => {
      if (uniqueIds.has(obj.id) && !seenIds.has(obj.id)) {
        seenIds.add(obj.id); // Track seen IDs
        return true; // Keep the object
      }
      return false; // Exclude duplicates
    });
    setCallParticipants(filteredObjects);
  };

  const handleVideoBtn = (e) => {
    if (localParticipant) {
      if (!videoEnabled) {
        publishVideoTrack();
      } else {
        handleUnpublishVideo();
      }
    }
  };

  const hansleParticipantsBtn = () => {
    setIsParticipantsOpen(!isParticipantsOpen);
  };

  const handleTranslationBtn = () => {
    if (translationEnabled) {
      handleSendCancelSpeakMessage();
      handleSendDenyTranslationsMessage();
      translationDisabledManuallyRef.current = true;
      translationEnabledRef.current = false;
      setTranslationEnabled(false);
    } else {
      handleSendRequestTranslationMessage();
    }
  };

  const verifyCallType = (call) => {
    if (call.other_participants.length > 1) {
      setCallType(GROUPCALL);
    } else {
      setCallType(ONECALL);
    }
  };

  const displaySingleCall = () => {
    if (!grid) {
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100% - 80px)",
            position: "relative",
          }}
          ref={containerRef}
        >
          <ParticipantComponent
            participant={remoteParticipant[0]}
            callInfo={data}
            grid={false}
          />
          {localParticipant.isCameraEnabled && (
            <SmallOwnCameraComponent
              participant={localParticipant.localParticipant}
              containerRef={containerRef}
            />
          )}
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            gap: "20px",
            width: "100%",
            height: "100%",
          }}
        >
          <ParticipantComponent
            localParticipant={true}
            participant={localParticipant.localParticipant}
            callInfo={data}
            grid={false}
          />
          <ParticipantComponent
            participant={remoteParticipant[0]}
            callInfo={data}
            grid={false}
          />
        </div>
      );
    }
  };

  const handleSwitchGrid = () => {
    setGrid(!grid);
  };

  const handleShareScreenBtn = () => {
    if (screenSharing) {
      stopScreenShare();
    } else {
      startScreenShare();
    }
  };

  const startScreenShare = async () => {
    const isElectron = !!window.electron;
    if (isElectron) {
      setIsPickingShareScreen(true);
    } else {
      startScreenShareWeb();
    }
  };

  const handleShareDesktopWindowSelected = async (mediaTrack) => {
    setIsPickingShareScreen(false);

    if (!mediaTrack) {

      return;
    }

    try {
      // Create a local video track for the selected screen source
      const screenVideoTrack = await new LocalVideoTrack(mediaTrack, {
        kind: Track.Source.ScreenShare,
        source: Track.Source.ScreenShare, // Metadata to identify it as a screen share
      });

      // Publish the track to LiveKit
      await localParticipant.localParticipant.publishTrack(screenVideoTrack, {
        source: Track.Source.ScreenShare, // Explicitly set as screen share
      });
      // Update the screen sharing state
      setScreenSharing(true);

    } catch (error) {
      console.error("Failed to share screen:", error);
    }
  };

  const startScreenShareWeb = () => {
    try {
      localParticipant.localParticipant
        .setScreenShareEnabled(true)
        .then(() => {
          setScreenSharing(true);
        })
        .catch(() => { });
    } catch (error) {
      console.error("Failed to share screen:", error);
    }
  };

  const stopScreenShare = () => {
    localParticipant.localParticipant
      .setScreenShareEnabled(false)
      .then(() => {
        setScreenSharing(false);
      })
      .catch(() => {

      });
  };

  const publishedTracksObserver = () => {
    if (!publishedTracks) {
      return;
    }
    handlePublishedScreenSharingTracks();

  };

  const handlePublishedScreenSharingTracks = () => {
    let foundScreenSharingTracks = publishedTracks.filter(
      (track) => track.source === "screen_share"
    );
    if (foundScreenSharingTracks.length > 0) {
      setParticipantIsSharingScreen(
        foundScreenSharingTracks[foundScreenSharingTracks.length - 1]
      );
      setScreenSharingParticipantsList(foundScreenSharingTracks);
      setGrid(false);
    } else {
      setParticipantIsSharingScreen(null);
      setScreenSharingParticipantsList([]);
      setScreenSharing(false);
    }
  };
  const handleSelectScreenShareParticipant = (participant) => {
    setParticipantIsSharingScreen(participant);
  };
  useEffect(() => {
    if (callingSoundHowl.playing() === true && remoteParticipant.length > 0) {
      callingSoundHowl.stop();
      connectedSoundHowl.play();
    }
  }, [remoteParticipant]);

  useEffect(() => {
    return () => {
      if (room) {
        room.disconnect();
        // setSpeakStatus(ENDED)
        sendCloseCallMessage(data.uuid);
        callingSoundHowl.stop();
        resetCall();
      }
    };
  }, []);

  useEffect(() => {
    if (speakStatus !== previousSpeakStatus && speakStatus === SPEAKING) {
      beepSoundHowl.play();
    }
    setPreviousSpeakStatus(speakStatus);
  }, [speakStatus]);

  useEffect(() => {
    if (!data) {
      return;
    }
    verifyCallType(data);
    mapCallParticipants(data);
  }, [data]);

  useEffect(() => {
    if (data.other_participants.length >= 1 && room.state === "connected") {
      if (remoteParticipant.length === 0) {
        room.disconnect();
        sendCloseCallMessage(data.uuid);
        callingSoundHowl.stop();
        resetCall();
      }
    }
  }, [remoteParticipant.length]);

  useEffect(() => {
    publishedTracksObserver();
  }, [publishedTracks]);

  return (
    <CallViewWrapper>
      {isPickingShareScreen && (
        <ShareScreenPicker
          onSelect={handleShareDesktopWindowSelected}
          onClose={() => setIsPickingShareScreen(false)}
        />
      )}
      <RoomAudioRenderer />
      {remoteParticipant.length === 0 && (
        <ConnectingView
          callInfo={data}
          handleCloseCallBtn={handleDisconect}
          sendCloseCallMessage={sendCloseCallMessage}
          resetCall={resetCall}
        />
      )}
      {remoteParticipant.length > 0 && (
        <>
          <CallHeader
            callDetails={data}
            handleSwitchGrid={handleSwitchGrid}
            translationEnabled={
              translationEnabled || participantIsSharingScreen
            }
          />
          <Content>
            {isParticipantsOpen && (
              <Participants
                callInfo={data}
                setLoading={setLoading}
                activeCallRef={activeCallRef}
                setSelectedCall={setSelectedCall}
                callParticipants={callParticipants}
              />
            )}

            <ConversationCore>
              {remoteParticipant.length === 1 &&
                participantIsSharingScreen === null &&
                displaySingleCall()}
              {remoteParticipant.length > 1 &&
                !translationEnabled &&
                !participantIsSharingScreen && (
                  <ParticipantsWrapper
                    callInfo={data}
                    participants={[localParticipant.localParticipant].concat(
                      remoteParticipant
                    )}
                    grid={grid}
                    participantIsSharingScreen={participantIsSharingScreen}
                  />
                )}

              {remoteParticipant.length > 1 &&
                translationEnabled &&
                !participantIsSharingScreen && (
                  <ParticipantsWrapperTranslationGrid
                    callInfo={data}
                    participants={[localParticipant.localParticipant].concat(
                      remoteParticipant
                    )}
                  />
                )}

              {remoteParticipant.length > 0 &&
                participantIsSharingScreen != null && (
                  <ScreenSharingGrid
                    callInfo={data}
                    participants={[localParticipant.localParticipant].concat(
                      remoteParticipant
                    )}
                    screenSharingParticipant={participantIsSharingScreen}
                    screenSharingParticipants={screenSharingParticipantsList}
                    handleSelectScreenShareParticipant={
                      handleSelectScreenShareParticipant
                    }
                  />
                )}

              <ControlBar
                handleCloseCallBtn={handleDisconect}
                handleVideoBtn={handleVideoBtn}
                videoEnabled={videoEnabled}
                handleMuteBtn={handleMuteBtn}
                micEnabled={micEnabled}
                hansleParticipantsBtn={hansleParticipantsBtn}
                isParticipantsOpen={isParticipantsOpen}
                handleTranslationBtn={handleTranslationBtn}
                translationEnabled={translationEnabled}
                handleShareScreenBtn={handleShareScreenBtn}
                screenSharing={screenSharing}
              />
            </ConversationCore>
            {translationEnabled && (
              <SpeakingTranslator
                micEnabled={micEnabled}
                handleTranscribeAudio={handleTranscribeAudio}
                speakMessagesList={speakMessagesList}
                speechRecongnitionMessages={speechRecongnitionMessages}
                speakStatus={speakStatus}
                handleSendCancelSpeakMessage={handleSendCancelSpeakMessage}
                setSpeakStatus={setSpeakStatus}
                handleRequestSpeakMessage={handleRequestSpeakMessage}
                speakersQueue={speakersQueue}
                muteMicrophone={muteMicrophone}
                unmuteMicrophone={unmuteMicrophone}
                micEnabledRef={micEnabledRef}
              />
            )}
          </Content>
        </>
      )}
    </CallViewWrapper>
  );
};

export default CallView;

const CallViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${colors.background};
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 0 20px;
`;

const ConversationCore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-width: 600px;
`;
