import React from 'react'
import styled from 'styled-components'
import { ReactComponent as AvatarUnset } from '../../Assets/icons/unsetAvatar.svg'
import { ReactComponent as Bell } from '../../Assets/icons/calls/bell-icon.svg'
import { colors } from '../../Assets/styles/colors'
import { localizationStrings } from '../../Localization/Localization'

const CurrentParticipant = ({ participant, online, callOfflineParticipant }) => {

  const parseStatus = () => {
    if (online) {
      return localizationStrings.call_modal.online
    } else {
      return localizationStrings.call_modal.offline
    }
  }
  return (
    <Container>
      <Avatar>
        {participant?.profile_picture ? <img src={participant?.profile_picture} alt='avatar' /> : <AvatarUnset />}
      </Avatar>
      <ContactNameWrapper>
        <Name>
          {participant.name}
        </Name>
        <Status offline={!online}>
          {parseStatus()}
          {!online &&
            <RingButton onClick={() => callOfflineParticipant(participant.id)}>
              <Bell />
            </RingButton>}
        </Status>
      </ContactNameWrapper>


    </Container>
  )
}

export default CurrentParticipant

const Container = styled.div`
display:flex;
align-items:center;
gap:10px;
padding: 0 16px;
height:40px;
min-height:40px;
max-height:40px;
`
const Avatar = styled.div`
 display: flex;
   height:35px;
  width:35px;
    max-width: 35px;
  min-height: 35px;
  min-width: 35px;
  max-height: 35px;
  overflow: hidden;
  border-radius: 100%;
    img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  svg{
     object-fit: cover;
    width: 100%;
    height: 100%;
  }
`

const ContactNameWrapper = styled.div`
display:flex;
align-items:center;
border-bottom:1px solid rgba(54, 62, 81, 0.2);
width:100%;
overflow:hidden;
gap:10px;
height:100%;
`

const Name = styled.p`
font-size: 16px;
font-weight: 400;
line-height: 20px;
color:${colors.white};
`

const Status = styled.p`
font-size: 12px;
font-weight: 500;
line-height: 20px;
text-align: left;
color:${props => props.offline ? colors.white : colors.purple};
margin-left:auto;
display:flex;
gap:5px;
`


const RingButton = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:transparent;
cursor:pointer;
svg{
max-height:20px;
max-width:20px;
color:${colors.purple};
}
`