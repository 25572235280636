import Recorder from "recorder-js";

class SimpleRecorderService {
  constructor() {
    this.audioContext = null;
    this.recorder = null;
    this.stream = null;
    this.analyser = null;
    this.dataArray = null;
    this.speakingThreshold = 0.01; // Adjust this threshold as needed
    this.isSpeaking = false;
  }

  async init() {}

  async start(callback) {
    this.recordingStoppedCallback = callback;
    this.cleanup();

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      this.recorder = new Recorder(this.audioContext);
      await this.recorder.init(this.stream);

      // Start recording
      await this.startRecording();
    } catch (err) {
      console.error("Error initializing RecorderService", err);
    }
  }

  async stop() {
    this.cleanup();
    await this.stopRecording(true);
  }

  async startRecording() {
    if (this.recorder) {
      try {
        await this.recorder.start();
      } catch (err) {
        console.error("Error starting recording", err);
      }
    }
  }

  async stopRecording(withCallback = true) {
    if (this.recorder) {
      try {
        const { blob } = await this.recorder.stop();
        if (this.recordingStoppedCallback && withCallback) {
          this.recordingStoppedCallback(blob); // Call the stored callback with the blob
        }
      } catch (err) {
        console.error("Error stopping recording", err);
        return null;
      }
    }
    return null;
  }

  cleanup() {
    if (this.audioContext && this.audioContext.state === "running") {
      this.audioContext.close();
    }
    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
  }
}

export default SimpleRecorderService;
