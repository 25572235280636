import { useLocalParticipant, useRoomContext } from "@livekit/components-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ParticipantComponent from "./ParticipantComponent";

const ParticipantsWrapper = ({
  callInfo,
  participants,
  grid,
  remoteParticipants,

}) => {
  const room = useRoomContext();
  const localParticipant = useLocalParticipant();
  const [speakingParticipant, setSpeakingParticipant] = useState(
    localParticipant.localParticipant
  );
  const speakerSelectedManually = useRef(false);


  const calculateColumns = (length) => {
    if (length <= 4) return 2; // Maximum 2 items per row for 4 or fewer items
    if (length <= 6) return 3; // Maximum 3 items per row for up to 6 items
    return 4; // Maximum 4 items per row otherwise
  };

  const columns = calculateColumns(participants.length);

  const handleParticipantSpeaking = (participant) => {
    participant.on("isSpeakingChanged", () => {
      if (participant.isSpeaking && speakerSelectedManually.current === false) {
        setSpeakingParticipant(participant);
      }
    });
  };

  const focusParticipant = (participant) => {
    speakerSelectedManually.current = true;
    setSpeakingParticipant(participant);
  }

  useEffect(() => {
    participants.forEach((participant) => {
      handleParticipantSpeaking(participant);
    });
  }, [room]);

  return (
    <Wrapper>
      {grid && (
        <GridViewContainer columns={columns}>
          {participants.map((participant) => (
            <ParticipantComponent
              participant={participant}
              callInfo={callInfo}
              key={participant.identity}
            />
          ))}
        </GridViewContainer>
      )}

      {!grid && (
        <FocusViewContainer>
          <ParticipantsListWrapper>
            {participants.map((participant) => (
              <ParticipantComponent
                participant={participant}
                callInfo={callInfo}
                key={participant.identity}
                grid={true}
                focusParticipant={focusParticipant}
              />
            ))}
          </ParticipantsListWrapper>
          <FocusWrapper>
            <ParticipantComponent
              participant={speakingParticipant}
              callInfo={callInfo}
              key={speakingParticipant.identity}
              grid={false}
              focused={true}
            />
          </FocusWrapper>
        </FocusViewContainer>
      )}
    </Wrapper>
  );
};

export default ParticipantsWrapper;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
const GridViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-auto-rows: 1fr;
  width: 100%;
  height: 100%;
  gap: 10px;
  max-height: 100%;
  overflow: auto;
  // display:flex;
  // flex-wrap:wrap;
  // justify-content:center;
  // gap:10px;
  // width:100%;
  // height:100%;
  // overflow:auto;
`;
const FocusViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 5px;
`;
const ParticipantsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  gap: 10px;
`;
const FocusWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
