import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import CurrentParticipant from "./CurrentParticipant";
import { localizationStrings } from "../../Localization/Localization";
import SelectableParticipant from "./SelectableParticipant";
import {
  useLocalParticipant,
  useRemoteParticipants,
} from "@livekit/components-react";
import { useConversationService } from "../../Context/ConversationsContext";
import { cloneDeep } from "lodash";
import { useCallsService } from "../../Context/CallsContext";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";
import SpinnerComponent from "../GenericComponents/SpinnerComponent";
import {
  isSubscriptionLevelEnoughForAccessLevel,
  ACCESS_LEVEL,
} from "../../Helpers/subscriptions/SubscriptionsHelper";
import { ACCESS_LEVEL_PLATFORM } from "../../Constants/constantValues";
import { useAuthService } from "../../Context/AuthContext";

const Participants = ({
  callInfo,
  setLoading,
  activeCallRef,
  setSelectedCall,
  callParticipants,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [allContacts, setAllContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const { getContactsOnPlatformForCurrentUser } = useConversationService();
  const { addCallParticipant, callOfflineParticipant } = useCallsService();
  const localParticipants = useLocalParticipant();
  const remoteParticipants = useRemoteParticipants();
  const { checkIfUserHasPermisionFor } = useAuthService();

  const handleGetContacts = () => {
    setIsLoadingContacts(true);
    getContactsOnPlatformForCurrentUser(
      function (response) {
        let sortedAppContacts = response.data["contacts"].sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        setContacts(sortedAppContacts);
        setAllContacts(sortedAppContacts);
        setIsLoadingContacts(false);
      },
      function (error) {
        console.log(error);
        setIsLoadingContacts(false);
      }
    );
  };

  const handleSelectContact = (contact) => {
    let newList = cloneDeep(selectedParticipants);
    let found = newList.find((selected) => selected.id === contact.id);
    if (found) {
      newList = newList.filter((selected) => selected.id !== found.id);
    } else {
      newList.push(contact);
    }
    setSelectedParticipants(newList);
  };

  const isSelected = (participant) => {
    if (selectedParticipants.find((item) => item.id === participant.id)) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddCallParticipant = (participants, call) => {

    let callOtherParticipants = call.other_participants.length;
    let participantsLength = participants.length;

    let totalNumberOfUsers = callOtherParticipants + participantsLength;

    var accessLevel = ACCESS_LEVEL.PRO;
    if (totalNumberOfUsers < 10) {
      accessLevel = ACCESS_LEVEL.ULTRA;
    } else {
      accessLevel = ACCESS_LEVEL_PLATFORM;
    }

    checkIfUserHasPermisionFor(
      accessLevel,
      () => {
        setLoading(true);
        let usersId = participants.map((item) => item.id);
        addCallParticipant(
          usersId,
          call.id,
          (response) => {
            setSelectedParticipants([]);
            setSelectedCall(response.data.details);
            activeCallRef.current.details = response.data.details;
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            notifyError(localizationStrings.profile.something_wrong);
          }
        );
      },
      () => {
        notifyError(localizationStrings.misc.need_to_upgrade_subscription);
      },
      () => {
        notifyError(localizationStrings.misc.need_to_upgrade_subscription);
      }
    );
  };

  const handleCallOfflineParticipant = (participantId) => {
    callOfflineParticipant(
      participantId,
      callInfo.id,
      (response) => {
        notifySuccess(localizationStrings.call_modal.notified_user);
      },
      (error) => {
        notifyError(localizationStrings.profile.something_wrong);
      }
    );
  };

  const handleSearch = () => {
    let filteredContacts = allContacts;
    if (searchValue) {
      filteredContacts = contacts.filter((contact) =>
        contact.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    }
    setContacts(filteredContacts);
  };

  const filterInvitedParticipants = (contacts) => {
    const filteredContacts = contacts.filter(
      (contact) => !callParticipants.some((participant) => participant.id === contact.id)
    );
    return filteredContacts

  }

  useEffect(() => {
    handleGetContacts();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchValue]);

  return (
    <Container>
      <ParticipantsWrapper>
        <Line>
          <Title>{localizationStrings.call_modal.participants}</Title>
        </Line>
        <Line>
          <Substitle>
            {localizationStrings.call_modal.current_participants}
          </Substitle>
        </Line>
        <CurrentParticipantsList>
          {callParticipants.map((participant) => (
            <CurrentParticipant
              key={participant.id}
              participant={participant}
              callOfflineParticipant={handleCallOfflineParticipant}
              online={remoteParticipants.find(
                (p) =>
                  p.identity == participant.id ||
                  localParticipants.localParticipant.identity == participant.id
              )}
            />
          ))}
        </CurrentParticipantsList>
        <Line>
          <Substitle style={{ textTransform: "uppercase" }}>
            {localizationStrings.call_modal.invite_participants}
          </Substitle>
        </Line>
        <Line>
          <SearchBox>
            <input
              value={searchValue}
              placeholder={localizationStrings.call_modal.search_by_name}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
          </SearchBox>
        </Line>
        <CurrentParticipantsList>
          {filterInvitedParticipants(contacts).map((contact) => (
            <SelectableParticipant
              key={contact.id}
              participant={contact}
              handleClick={() => handleSelectContact(contact)}
              selected={isSelected(contact)}
            />
          ))}
          <SpinnerComponent isLoading={isLoadingContacts}></SpinnerComponent>
        </CurrentParticipantsList>
      </ParticipantsWrapper>

      <Bottom>
        {selectedParticipants?.length > 0 && (
          <InviteButton
            onClick={() =>
              handleAddCallParticipant(selectedParticipants, callInfo)
            }
          >
            {localizationStrings.call_modal.invite}
          </InviteButton>
        )}
      </Bottom>
    </Container>
  );
};

export default Participants;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 400px;

  *::-webkit-scrollbar-track {
    background: rgba(26, 29, 36, 0); /* Track background */
  }

  *::-webkit-scrollbar-thumb {
    background: rgba(26, 29, 36, 0); /* Track background */
  }
`;
const ParticipantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: ${colors.callComponent.translatedListMessagesBg};
  padding: 20px 10px;
  border-radius: 10px;
  overflow: auto;
  gap: 20px;
`;

const Bottom = styled.div`
  display: flex;
  width: 100%;
  min-height: 80px;
  padding: 18px 0 10px;
`;
const InviteButton = styled.button`
  display: flex;
  width: 100%;
  padding: 18px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: ${colors.white};
  background: ${colors.purple};
  border: none;
  border-radius: 10px;
  max-height: 60px;
  cursor: pointer;
`;
const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.callControlBar.buttonLabelColor};
`;

const Line = styled.div`
  display: flex;
  padding: 5px 16px;
  align-items: center;
  width: 100%;
`;
const Substitle = styled.p`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  color: rgba(193, 194, 203, 0.6);
`;
const CurrentParticipantsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const SearchBox = styled.div`
  background: rgba(118, 118, 128, 0.24);
  display: flex;
  padding: 14px;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: ${colors.white};
    font-size: 14px;
    line-height: 16px;
  }
`;
