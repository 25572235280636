import React from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as PencilIcon } from "../../Assets/icons/context-menu-icons/EditIcon.svg"
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg"
import FileAttachmentItem from './FileAttachmentItem'
import { localizationStrings } from '../../Localization/Localization'

const EditMessageContainer = ({ message, handleCloseEdit }) => {
    return (
        <Container>
            <EditWrapper>
                <PencilIcon />
            </EditWrapper>

            <MessageWrapper>
                <h1>{localizationStrings.chat.edit_message}</h1>
                <Message>
                    <p>{message.original_message}</p>
                </Message>
            </MessageWrapper>

            {message.file &&
                <MessageWrapper>
                    <FileAttachmentItem
                        file={message.file}
                    />
                </MessageWrapper>
            }
            {message.image &&
                <MessageWrapper>
                    {message.image &&
                        <img src={message.image.url} alt="reply_image" />
                    }
                </MessageWrapper>
            }
            {message.video &&
                <MessageWrapper>
                    {message.video &&
                        <img src={message.video.thumbnail.url} alt="reply_image" />
                    }
                </MessageWrapper>
            }
            <CloseBtn onClick={() => handleCloseEdit()}>
                <CloseIcon />
            </CloseBtn>
        </Container>
    )
}

export default EditMessageContainer

const Container = styled.div`
display:flex;
align-items:center;
background:${colors.userMenuHeaderBg};
z-index:10;
max-height:300px;
height:auto;
position:absolute;
width:100%;
bottom:74px;
svg{
    color:white;
}
@media(max-width:990px){
    max-height:100px;
}
`

const EditWrapper = styled.div`
display:flex;
height:100%;
align-items:center;
border-right:2px solid ${colors.purple};
padding: 10px 20px;
`
const MessageWrapper = styled.div`
display:flex;
flex-direction:column;
overflow:hidden;
gap:5px;
padding:10px;
max-height:100px;
height:100%;
width: 100%;
position:relative;
h1{
    color:${colors.purple};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}
img {
    max-width:400px;
    margin-left: auto;
    max-height: 80px;
  }

@media(max-width:990px){
    max-height:100px;
    img {
          max-height: 60px;
    }
}
`
const CloseBtn = styled.button`
margin-left:auto;
margin-right:20px;
border:none;
background:none;
cursor:pointer;

`

const Message = styled.div`
display:flex;
height:100%;
overflow:auto;
p{
    color:${colors.white};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
`