import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as Crown } from "../../Assets/icons/subscription/crown.svg";
import { ReactComponent as Marked } from "../../Assets/icons/subscription/check.svg";
import { ReactComponent as Unmarked } from "../../Assets/icons/subscription/noCheck.svg";
import { localizationStrings } from "../../Localization/Localization";
import { IOSSwitch } from "../GenericComponents/CustomSwitch";

export const benefitsList = [
  {
    title: localizationStrings.subscription_modal.benefit1Title,
    description: localizationStrings.subscription_modal.benefit1Description,
  },
  {
    title: localizationStrings.subscription_modal.benefit2Title,
    description: localizationStrings.subscription_modal.benefit2Description,
  },
  {
    title: localizationStrings.subscription_modal.benefit3Title,
    description: localizationStrings.subscription_modal.benefit3Description,
  },
  {
    title: localizationStrings.subscription_modal.benefit4Title,
    description: localizationStrings.subscription_modal.benefit4Description,
  },
  {
    title: localizationStrings.subscription_modal.benefit5Title,
    description: localizationStrings.subscription_modal.benefit5Description,
  },
];

const SubscriptionPlanPicker = ({
  payYearly,
  setPayYearly,
  selectedSubscriptionPlan,
  subscribeTo,
  setSubscribeTo,
  sendInvites,
}) => {
  const subscriptionPlans = [
    {
      title: "FREE",
      priceMonth: "FREE",
      priceYear: "FREE",
      benefits: [true, false, false, false, false],
    },

    {
      title: "PRO",
      priceMonth: "$1.99",
      priceYear: "$19.99",
      benefits: [true, true, true, true, false],
      crown: true,
      onClick: () => setSubscribeTo("pro"),
      value: "pro",
    },
    {
      title: "ULTRA",
      priceMonth: "$4.99",
      priceYear: "$49.99",
      benefits: [true, true, true, true, true],
      crown: true,
      onClick: () => setSubscribeTo("ultra"),
      value: "ultra",
    },
  ];

  return (
    <SubscriptionPlanPickerWrapper>
      <InviteWrapper>
        <InviteTextWrapper>
          <SmallInfoText>
            {localizationStrings.subscription_modal.invite_five}
          </SmallInfoText>
          <HighlightedText>
            {localizationStrings.subscription_modal.one_month_free_pro}
          </HighlightedText>
        </InviteTextWrapper>
        <SendInviteBtn onClick={sendInvites}>
          {localizationStrings.subscription_modal.send_invites}
        </SendInviteBtn>
      </InviteWrapper>
      <PlansWrapper>
        <Benefits style={{ maxWidth: "150px" }}>
          <BenefitsCell>
            <TitleInfoText style={{ color: "rgba(149, 152, 157, 1)" }}>
              {localizationStrings.subscription_modal.benefits}
            </TitleInfoText>
          </BenefitsCell>
          {benefitsList.map((benefit, index) => (
            <BenefitsCell key={index} style={{ minWidth: "150px" }}>
              <TitleInfoText>{benefit.title}</TitleInfoText>
              <SmallInfoText>{benefit.description}</SmallInfoText>
            </BenefitsCell>
          ))}
          <BenefitsCell>
            <TitleInfoText>
              {localizationStrings.subscription_modal.price}
            </TitleInfoText>
          </BenefitsCell>
        </Benefits>

        <PlanSelectors>
          {subscriptionPlans.map((subscriptionPlan, index) => (
            <PlanBtn
              key={subscriptionPlan.title}
              selected={subscriptionPlan.value === subscribeTo}
              onClick={subscriptionPlan.onClick}
            >
              <BenefitsCell>
                <PlanTitleWrapper>
                  {subscriptionPlan.crown && <Crown />}
                  <TitleInfoText>{subscriptionPlan.title}</TitleInfoText>
                </PlanTitleWrapper>
              </BenefitsCell>
              {subscriptionPlan.benefits.map((benefit, index) => (
                <BenefitsCell>
                  <BenefitCheckWrapper>
                    {benefit ? <Marked /> : <Unmarked />}
                  </BenefitCheckWrapper>
                </BenefitsCell>
              ))}
              <BenefitsCell>
                <PlanPriceWrapper style={{ gap: 0 }}>
                  <PlanPriceText>
                    {payYearly && subscriptionPlan.priceYear}
                    {!payYearly && subscriptionPlan.priceMonth}
                  </PlanPriceText>

                  {subscriptionPlan.value && (
                    <PriceSmallInfoText style={{ color: "white" }}>
                      {payYearly
                        ? `/${localizationStrings.subscription_modal.year}`
                        : `/${localizationStrings.subscription_modal.month}`}
                    </PriceSmallInfoText>
                  )}
                </PlanPriceWrapper>
              </BenefitsCell>
            </PlanBtn>
          ))}
        </PlanSelectors>
      </PlansWrapper>
      <PayTypeWrapper>
        <InviteTextWrapper>
          <HighlightedText style={{ fontSize: "16px", fontWeight: 600 }}>
            {localizationStrings.subscription_modal.pay_anually}
          </HighlightedText>
          <SmallInfoText style={{ fontWeight: "400", color: "white" }}>
            {localizationStrings.subscription_modal.pay_text1}
            <span>{localizationStrings.subscription_modal.pay_text2}</span>
            {localizationStrings.subscription_modal.pay_text3}
          </SmallInfoText>
        </InviteTextWrapper>
        <SavePercentWrapper>
          <SavePercentText>{localizationStrings.pro_card.save}</SavePercentText>
          <SavePercent>17%</SavePercent>
        </SavePercentWrapper>
        <IOSSwitch
          checked={payYearly}
          onChange={(e) => setPayYearly(e.target.checked)}
        />
      </PayTypeWrapper>
    </SubscriptionPlanPickerWrapper>
  );
};

export default SubscriptionPlanPicker;

const SubscriptionPlanPickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const InviteWrapper = styled.div`
  padding: 15px 0;
  display: flex;
  width: 100%;
  border-top: 1px solid rgba(46, 50, 62, 1);
  border-bottom: 1px solid rgba(46, 50, 62, 1);
`;

const InviteTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
const HighlightedText = styled.h3`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: ${colors.modalGreenColor};
`;
const TitleInfoText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: white;
`;
const SmallInfoText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgba(149, 152, 157, 1);
  span {
    font-weight: 600;
  }
`;

const SendInviteBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: ${colors.purple};
  margin-left: auto;
  cursor: pointer;
`;

const PlansWrapper = styled.div`
  display: flex;
  gap: 3px;
  width: 100%;
  padding: 20px 0;
`;
const Benefits = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const BenefitsCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  min-height: 50px;
  justify-content: center;
  width: 100%;
  min-width: 80px;
`;
const PlanSelectors = styled.div`
  display: flex;
  width: 100%;
`;

const PlanBtn = styled.button`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 12px;
  background: ${(props) =>
    props.selected ? colors.modalDarkPurpleBg : "none"};
  border: ${(props) =>
    props.selected ? "1px solid rgba(111, 105, 255, 1)" : "none"};
  overflow: hidden;
  width: 100%;
`;
const BenefitCheckWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(46, 50, 62, 1);
  border-right: 1px solid rgba(46, 50, 62, 1);
  width: 100%;

  &:last-child {
    border-right: none;
  }
`;
const PlanTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;
const PlanPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
`;
const PlanPriceText = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: ${colors.modalGreenColor};
`;
const PayTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid rgba(46, 50, 62, 1);
  gap: 20px;
`;
const PriceSmallInfoText = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: white;
`;
const SavePercentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
`;
const SavePercent = styled.div`
  border-radius: 12px;
  padding: 3px 10px;
  background: rgba(52, 52, 100, 1);
  display: flex;
  color: white;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
`;

const SavePercentText = styled.p`
  color: rgba(149, 152, 157, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
`;
