import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import {
  findTranslatedTextForOtherUser,
  findTranslatedTextForUser,
} from "../../Helpers/chatHelper/ChatHelper";
import { useAuthService } from "../../Context/AuthContext";

const CallTranslatedMessage = ({ message, ownMessage }) => {
  const { user } = useAuthService();
  const translationMessage = findTranslatedTextForUser(
    message.call_message_translations,
    user
  );

  return (
    <Container ownMessage={ownMessage}>
      {!ownMessage && <User>{message?.user?.name}</User>}
      <MessageWrapper>
        <OriginalMessage>{message?.original_message}</OriginalMessage>
        {!ownMessage && (
          <TranslatedMessage>
            {translationMessage?.translation}
          </TranslatedMessage>
        )}
      </MessageWrapper>
    </Container>
  );
};

export default CallTranslatedMessage;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 30px;
  width: 100%;
  align-items: ${({ ownMessage }) => (ownMessage ? "flex-end" : "flex-start")};
  text-align: ${({ ownMessage }) => (ownMessage ? "right" : "left")};
`;
const User = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${colors.purple};
`;
const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
const OriginalMessage = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: ${colors.callComponent.originalMessageColor};
`;
const TranslatedMessage = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.callComponent.translatedMessageColor};
`;
