import { useState, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../Assets/styles/colors";
import { ReactComponent as CloseIcon } from "../Assets/icons/closeIcon.svg";

import { localizationStrings } from "../Localization/Localization";

const ShareScreenPicker = ({ onSelect, onClose }) => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  const handleScreenShareSelect = async (source) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        mandatory: {
          chromeMediaSource: "desktop",
          chromeMediaSourceId: source.id,
        },
      },
    });

    if (stream) {
      let tracks = stream.getVideoTracks();
      if (tracks.length > 0) {
        onSelect(tracks[0]);
        return;
      }
    }

    onSelect(null);
  };

  useEffect(() => {
    const fetchSources = async () => {
      setLoading(true); // Show spinner during loading
      window.electron.getScreenShareSources((fetchedSources) => {
        setSources(fetchedSources);
        setLoading(false); // Hide spinner when done
      });
    };

    fetchSources();
  }, []);

  return (
    <Overlay>
      <PickerContainer>
        <Header>
          <Title>{localizationStrings.share_screen.select_screen}</Title>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Header>
        <Content>
          {loading ? (
            <SpinnerWrapper>
              <Spinner />
              <SpinnerText>{localizationStrings.share_screen.loading}</SpinnerText>
            </SpinnerWrapper>
          ) : (
            sources.map((source) => (
              <ScreenItem
                key={source.id}
                onClick={() => handleScreenShareSelect(source)}
              >
                <Thumbnail src={source.thumbnail} alt={source.name} />
                <ScreenName>{source.name}</ScreenName>
              </ScreenItem>
            ))
          )}
        </Content>
      </PickerContainer>
    </Overlay>
  );
};

export default ShareScreenPicker;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const PickerContainer = styled.div`
  background: #fff;
  border-radius: 12px;
  width: 600px;
  background: ${colors.background};
  max-width: 90%;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.white};
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
  min-height: 200px; /* To center spinner when loading */
`;

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Spinner = styled.div`
  border: 4px solid ${colors.newChatModalBg};
  border-top: 4px solid ${colors.white};
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerText = styled.p`
  margin-top: 8px;
  color: ${colors.white};
  font-size: 14px;
`;

const ScreenItem = styled.div`
  cursor: pointer;
  width: calc(50% - 16px); /* Two items per row with gap */
  text-align: center;
  border: 1px solid ${colors.newChatModalBg};
  border-radius: 8px;
  padding: 10px;
  background-color: ${colors.newChatModalBg};
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const ScreenName = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${colors.white};
`;
