import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";

const ControlButton = ({
  activeIcon,
  inactiveIcon,
  active,
  label,
  ...props
}) => {
  return (
    <Wrapper>
      <CustomButton {...props} $active={active}>
        {active ? activeIcon : inactiveIcon}
      </CustomButton>
      <CustomLabel>{label}</CustomLabel>
    </Wrapper>
  );
};

export default ControlButton;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 43px;
  align-items: center;
  gap: 5px;
`;
const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  border: none;
  cursor: pointer;
  overflow: hidden;
  background: transparent;
`;

const CustomLabel = styled.label`
  font-size: 10px;
  color: ${colors.callControlBar.buttonLabelColor};
  line-height: 16px;
  text-align: center;
`;
