export const EN = {
  language_name: "English",
  language_code: "EN",
  invite: "Invite",
  delivered: "Delivered",
  read: "Read",
  translated_message: "Translated message",

  login: {
    login: "Login",
    login_with_phone: "Login with your phone number",
    login_agree: "By Loging In you agree with our",
    select_login_method: "Select a login method from below.",
    terms_and_conditions: "Terms and Conditions",
    enter_code: "Enter the code we just sent you",
    wrong_validation_code:
      "Sorry, the validation code is incorrect. Please try with another code.",
    code_not_received: "Didn’t receive any code?",
    resend_code: "Resend code",
    app_language: "Select app language",
    verify_code: "Verify Code",
    continue: "Continue",
    code_sent: "Code sent",
    authenticate: "Authenticate",
    go_to_desktop: "Open desktop app",
    successfull_log_in: "You have sucessfully logged in",
    loginSSO: "Microsoft",
    loginGoogleSSO: "Google",
  },

  register: {
    next_button: "Next",
    your_name: "Your Name",
    name_details: "Tell us how should other people on the platform call you",
    name_placeholder: "Type your name...",
    chat_language: "Chat language",
    language_details:
      "You will receive the calls and messages in the chosen language.",
    birthdate: "Birthdate",
    birthdate_details:
      "We'll use your birthdate to make message translations better tailored to you.",
    dont_want_to_share: "Don't want to share",
    gender: "Gender",
    gender_details:
      "We'll take your gender into account to further personalize your communication.",
    cultural_background: "Cultural background",
    cultural_background_details:
      "We use your cultural background information, to fine-tune your communication.",
    personal_details: "Personal details",
    personal_details_text:
      "We’ll use your details to make translations better tailored to you. Provide the details now or later from your profile section.",
    i_will_complete_later: "I’ll complete it later",
    finish: "Finish",
  },

  profile: {
    my_profile: "My profile",
    back: "Back",
    personal_details: "PERSONAL DETAILS",
    chat_settings: "CHAT SETTINGS",
    call_settings: "CALL SETTINGS",
    account_details: "ACCOUNT DETAILS",
    others: "OTHERS",
    language: "Language",
    birthday: "Birthday",
    gender: "Gender",
    cultural_background: "Cultural Background",
    phone_number: "Phone Number",
    phone: "Phone",
    email: "Email",
    logout: "Logout",
    delete_account: "Delete Account",
    term_and_conditions: "Terms & Conditions",
    privacy: "Privacy Policy",
    support: "Support",
    please_select_language: "Please select language",
    please_select_gender: "Please select your gender",
    type_cultural_background: "Type your cultural background",
    select_birthdate: "Select birthdate",
    type_your_status: "Type your status",
    not_connected:
      "You’re not connected to the internet, please check your connection!",
    something_wrong: "Something wrong",
    cant_have_conv_with_you: "You can't have conversation with yourself",
    profile_updated: "Profile updated",
    version: "Version",
    delete_account_info:
      "Are you sure you want to delete your account? This will delete all your data.",
    invite_new_contact: "Invite someone",
    account_closure: "ACCOUNT CLOSURE",
    block_users: "Block users",
    unknown_users: "UNKNOWN USERS",
    block_unknown_users: "Block unknown users",
    subscription: "Subscription",
    unsubscribe: "Unsubscribe",
    revokeAdmin: "Revoke",
    unsubscribeConfirmationMessage:
      "Are you sure you want to unsubscribe? You’ll loose access to all the benefits",
    revokeAdminConfirmationMessage:
      "Are you sure you want to revoke admin rights for this member?",
    deleteChat: "Are you sure you want to remove this conversation?",
    profile_picture_changed: "Profile picture changed",
    contact_added: "Contact added",

  },

  chat: {
    was_invited: "was invited",
    invited_to_conversation:
      "This phone number does not have an emotii account, we’ve sent them an SMS message to join the platform. Once they’ve created an account, you can begin to chat.",
    speak_in_your_language: "Speak in your language, we'll handle the rest!",
    new: "New",
    original_message: "Original Message",
    preffered_language: "Preferred Language",
    image: "Image",
    search: "Search",
    message_deleted: "Message deleted",
    type_your_message: "Type your message",
    send_image: "Send Image",
    send_media: "Send Media",
    send_file: "Send File",
    send_to: "Send to",
    close: "Close",
    rating_modal_message:
      "How good are the translations in this conversations?",
    remove_member: " Are you sure you want to remove this member?",
    rating_sent: "Rating sent",
    original_in: "Original in",
    forward: "Forward",
    reply: "Reply",
    copy: "Copy",
    edit: "Edit",
    report: "Report",
    info: "Info",
    delete: "Delete",
    forward_to: "Forward To",
    forward_message: "Forward Message",
    chats: "Chats",
    add_caption: "Add a caption",
    file: "File",
    video: "Video",
    deleted: "Deleted",
    generated_qc_link: "Generated Quick Chat Link",
    file_size_exceded: "File size exceeds the limit. Please ensure the file is less than ",
    valid_file: "Please select valid file",
    edit_message: "Edit message"
  },

  new_chat: {
    new_chat: "New Chat",
    new_group: "New Group",
    group: "Group",
    chat: "Chat",
    start_chat: "Start chat",
    name: "NAME",
    first_name: "First name",
    last_name: "Last Name",
    search_member: "Search Member",
    add_member: "Add Member",
    found_contact: "Found Contact",
    members: "Members",
    enter_group_name: "Enter group name",
    contact_not_emotii_user: "Contact is not an Emotii user",
    create: "Create",
    create_group: "Create Group",
    invite_sent: "Invite sent",
    add_members: "Add Members",
    contacts_on_emotii: "Contacts on emotii",
    invite_to_emotii: "Invite to emotii",
    new_contact: "New Contact",
    save: "Save",
    members_added: "Members added",
    add_contact: "Add Contact",
    contacts: "Contacts",
    cant_have_conv_with_u: "You can’t have a conversation with yourself.",
    quick_chat: "Quick Chat",
    quick_chat_placeholder: "Quick chat name",
    new_quick_chat: "New Quick Chat",
    create_quick_chat: "Create Quick Chat",
    email_placeholder: "Type email adress",

  },

  quick_chat: {
    invited_by: "You're invited to join the",
    emotii_chat: "emotii chat",
    enter_name_and_language:
      "Enter your name and preferred language to start chatting.",
    your_name: "Your name",
    chat_language: "Chat Language",
    caution:
      "Be careful: anyone with this link can see your quick chat conversations. Share it only with the people you want to chat with. Use quick chats temporarily and make sure to close them when you're done by tapping the close button below",
    cautionModal:
      "Be careful: anyone with this link can see your quick chat conversations. Share it only with the people you want to chat with. Use quick chats temporarily and make sure to close them when you're done by tapping the name in the header and pressing the close button.",
    caution_close_qc:
      "Are you sure you want to end this quick chat? Once you do, it will no longer be accessible to the other participant.",
    cancel: "Cancel",
    join: "Join emotii and keep your chats forever.",
    title1: "This conversation",
    title2: " doesn’t exist anymore!",
    registerText: "   Register on emotii to create chats",
    registerTextMobile: "   Register on emotii",
    close: "Chat Closed",
    closed: "This chat is closed",
    copy_link: "Copy Link",
    close_chat: "Close chat",
    register_text: "   Register on emotii to create chats",
    register_text_mobile: "   Register on emotii",
  },

  chat_details: {
    group_details: "Group Details",
    user_details: "User details",
    account_details: "Account details",
    save_changes: "Save changes",
    details: "Details",
    exit_group: " Are you sure you want to exit this group?",
    yes: "Yes",
    no: "No",
    member_added: "Member added",
    generated_link: "GENERATED LINK",
    message_details: "Message Details",
    delivered_to: "Delivered to",
    read_by: "Read by",
    make_admin: "Make Admin",
    revoke_admin: "Revoke Admin",
    remove_user: "Remove user",
    leave_group: "Leave group",
    scan_easy_access: "SCAN FOR EASY ACCESS",
  },

  context_menu: {
    delete_chat: "Delete chat",
    hide_chat: "Hide chat",
    delete_call: "Delete call",
    delete_call_message: "Are you sure you want to delete this call?",
    message_mood_info_text: "The mood of this message is",
  },

  block_contacts: {
    blocked: "BLOCKED",
    blocked_contact: "Blocked contact",
    blocked_contact_modal_text:
      "You have blocked this contact. To send a message, please unblock them by pressing the button below. Note that they will be able to contact you once unblocked.",
    block: "Block",
    unblock: "Unblock",
    unblocked: "UNBLOCKED",
    unblockConfirmationMessage:
      "This action will allow this user to contact you. Are you sure you want to proceed?",
    blockConfirmationMessage:
      "Blocking this user will prevent them from contacting you. Are you sure you want to proceed?",
    this_contact_is_blocked: "This contact is blocked"
  },

  onboardingPage: {
    calls_language: "Calls language",
    chat_and_calls: "Chat and calls preference",
    select_calls_placeholder: "Select calls preferred language",
    chat_language_placeholder: "Select chat preferred language",
    your_name: "Your name",
    chat_prefference: "Chat preference",
    chat_language: "Chat language",
    birthdate_placeholder: "Type or select your birthdate",
    your_gender: "Your gender",
    cultural_bg: "Cultural background",
    cultural_bg_intro:
      "In order to create a more accurate and personalized translation for you, we would like to know a few details about your background. For example, could you share with us:",
    cultural_bg_item1: "Your profession or field of work",
    cultural_bg_item2: "The country and region you're from",
    cultural_bg_item3:
      "Any specific cultural or linguistic influences that might shape the way you communicate (such as dialects, regional expressions, or customs)",
    cultural_bg_item4:
      "Any preferences for tone or style (formal, informal, professional, conversational)",
    cultural_bg_info:
      "This information will help our AI system provide a translation that feels more natural and tailored to your unique context.",
    close: "Close",
  },

  welcome_component: {
    title: "Welcome to ",
    titleGradient: "emotii",
    subtitle:
      "Break language barriers and connect authentically with people from different cultures, spiecialties, and languages.",
    info1Span: "Real-Time Voice Translations",
    info1: "Speak naturally, we translate as you talk.",
    info2Span: "Supports 120+ Languages",
    info2: "Communicate effortlessly in your preferred language.",
    info3Span: "Beyond Translation",
    info3: "Build genuine understanding and better connections worldwide.",
    tap_message: `Tap "Get Started" to begin your journey towards seamless global communication!`,
    get_started: "Get Started",
  },

  pro_card: {
    try_new: "Try now PRO Subscription",
    save: "save",

  },
  toggle_list: {
    messages: "Messages",
    calls: "Calls",
    translate: "Translate",
  },
  request_notifications_modal: {
    title: "Notifications",
    info: "  Would you like to enable push notifications for emotii? You will be notified of new messages and calls.",
    cancel: "Cancel",
    enable: "Enable",
  },

  first_time_usage: {
    title: "Welcome to emotii!",
    subtitle: "We're thrilled you're here. Let's begin:",
    step1Span: "Step 1",
    step1: `Tap "New" to start chatting.`,
    step2Span: "Step 2",
    step2: "Invite friends or family to join the fun.",
    step3Span: "Step 3",
    step3: "Customize your profile.",
    proTipSpan: "Pro Tip",
    proTip: "Upgrade to Pro for real-time voice translation during calls!",
    enjoy: "Enjoy connecting globally!",
  },

  subscription_modal: {
    join_text:
      "Subscribe to emotii for seamless audio & video calls and live translation. Effortless conversations.",
    joined_text:
      "Enjoy your Emotii PRO for seamless audio calls and live translation. Effortless conversations.",
    subscribe_title: "Subscribe Now",
    pro_title: "PRO Subscription plan ",
    ultra_title: "ULTRA Subscription plan ",
    platform_title: "Subscription plan ",
    send_invitations: "Send invitations",
    send_invites: "Send invites",
    unsubscribe: "Unsubscribe",
    active_until: "You have an active subscription until",
    start3Days: "Start 3-Days Free PRO Trial",
    subscribe: "Subscribe",
    terms: "Terms & Conditions",
    invite_five: "Invite 5 friends to get",
    canceled: "Subscription cancelled",
    benefits: "Benefits",
    benefit1Title: "Chats",
    benefit1Description: "Unlimited connectivity",
    benefit2Title: "HD Audio Calls",
    benefit2Description: "Crisp and clear",
    benefit3Title: "Voice Translation",
    benefit3Description: "Seamless communication",
    benefit4Title: "Video Calls",
    benefit4Description: "Talk and see in your language",
    benefit5Title: "Group Calls",
    benefit5Description: "Up to 10 participants",
    price: "PRICE",
    month: "month",
    year: "year",
    one_month_free_pro: "One month FREE PRO",
    pay_anually: "PAY ANNUALLY",
    pay_text1: "Get ",
    pay_text2: "2 months free",
    pay_text3: " with a yearly subscription!",
    switch_to_payd: "Switch to a paid subscription"
  },

  subscribed_plan_card: {
    refferal: "Referral campaign remaining",
    platform: "You're account is subscribed from the platform",
    next_payment: "Next payment",
    active_util: "Active until",
    monthly: "Monthly",
    yearly: "Yearly",
    one_month_free: "One month FREE",
    one_month_free_pro: "One month FREE PRO",
    your_benefits: "Your Benefits",
    invitation_info: "You have sent 5 invitations"
  },

  refferal_modal: {
    title: "Join emotii",
    invited_you:
      "has invited you to join emotii. Tap the link below and start communicating without boundaries.",
    you_are_invited:
      "You're invited to join emotii. Tap the link below and start communicating without boundaries.",
    copied: "Link copied",
    refferal: "Referral",
    congrats: "Congrats, you got",
    invite5: " Invite at least 5 friends and get",
    moth_free: " 1 Month of Premium subscription for FREE",
    referral_link: "Your refferal link",
  },

  call_modal: {
    incoming_audio: "You have an incoming call, do you wish to answer?",
    incoming_video: "You have an incoming video call, do you wish to answer?",
    decline: "Decline",
    answer: "Answer",
    mute: "Mute",
    speaker: "Speaker",
    video: "Video",
    share_screen: "Share screen",
    participants: "Participants",
    translate: "Translate",
    realeaseMessage:
      "Recording and translating, release the button (or space bar) to finish.",
    pressMessage:
      "Keep the button pressed (or use the space bar) to translate your message",
    current_participants: "CURRENT PARTICIPANTS",
    invite_participants: "Invite participants",
    connecting: "Connecting",
    invite: "Invite",
    search_by_name: "Search by name",
    notified_user: "The user was notified!",
    enable_calling_message: "To enable calling, please allow microphone access for this app in your browser’s settings.",
    online: "Online",
    offline: "Offline",
    video_call: "Video call"
  },
  call_status: {
    ongoing: "Ongoing",
    click_join: "click to join",
    outgoing: "Outgoing",
    incoming: "Incoming",
    missed: "Missed"
  },
  share_screen: {
    select_screen: "Select a Screen to Share",
    loading: "Loading..."
  },
  request_translation_modal: {
    title: "Translation Request",
    request:
      "Other participants have requested to enable speech translations. If you accept, you will hear audio translations for your messages. Would you like to enable them?",
    turned_of_translation: "Your request for speech translation was denied by the other participant(s). Translations are now turned off. ",
    deny: "Deny",
    enable: "Enable",
    request_language: "The other participant has requested to enable speech translations. Please select a supported language from Settings -> Call Settings",
    mic_info: "Your microphone is muted. Unmute to speak."
  },
  translation_request_sent:
    "Your translation request has been sent for approval to the other participant(s).",
  speech_container: {
    speak_now: "Speak now",
    note: "Please note: Speech recognition accuracy depends on your language and how you speak. For best results, speak only in ",
    note2:
      "and turn off original audio in noisy environments. Use it at your own discretion. You can toggle between automatic and manual recognition with the toggle above.",
  },
  gender_selector: {
    select: "Select a gender",
  },
  language_selector: {
    language_selector: "Available languages",
  },
  message_mood: {
    info: "Define the style for you messages to improve translation.",
  },
  grovs: {
    messages: "Messages",
    no_messages: " There are currently no messages.",
  },
  delete_account_modal: {
    reason1: "I’m worried about my data privacy and security.",
    reason2: "I experience frequent bugs, crashes, or slow performance.",
    reason3: "I’ve found a better alternative app.",
    reason4: "I no longer find the app necessary or relevant.",
    reason5: "Something else.",
    delete: "Delete account",
    are_you_sure: "Are you sure you want to delete your acoount?",
    action:
      "This action is permanent and will remove all your data, content, and access rights. ",
    specifically:
      "Specifically, all your conversations, files sent, and data stored will be permanently deleted and cannot be recovered.",
    reasons: "Reasons",
    reasons_info:
      "Select one or multiple reasons why you want to delete your account.",
    confirmation: "Confirmation",
    i_want: "I want to delete my account",
  },
  misc: {
    link_copied: "Link copied",
    video_access:
      "To enable video calling, please allow video access for this app in your browser’s settings.",
    platform_error:
      "This feature isn’t available in your subscription. Contact your administrator to enable it, or upgrade your subscription plan.",
    need_to_upgrade_subscription:
      "You need to upgrade your subscription for this action!",
    copy: "Copy"
  },
};
