import {
    createContext,
    useContext,
    useCallback,
    useState,
    useRef,
} from "react";
import { addCallParticipantAiCall, callOfflineParticipantAiCall, createGroupCallApiCall, deleteCallAPICall, getCallDetailsApiCall, getCallsForCurrentUserApiCall, speakAudioAPICall, transcribeAPICall } from "../API/calls/callsService";
import moment from "moment";


export const CallsContext = createContext({
    calls: [],
    callsRef: [],
    createGroupCall: () => { },
    getCalls: () => { },
    getCallDetails: () => { },
    deleteCall: () => { },
    transcribeAudio: () => { },
    speakAudio: () => { },
    setCalls: () => { },
    addCallParticipant: () => { },
    callOfflineParticipant: () => { },

});

const CallsContextProvider = ({ children }) => {

    const [calls, setCalls] = useState([]);
    const callsRef = useRef(null);

    const createGroupCall = useCallback(
        async (users_id, group_id, video, onSuccess, errorCallback) => {
            try {
                await createGroupCallApiCall(
                    users_id, group_id, video,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const callOfflineParticipant = useCallback(
        async (user_id, call_id, onSuccess, errorCallback) => {
            try {
                await callOfflineParticipantAiCall(
                    user_id, call_id,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const addCallParticipant = useCallback(
        async (users_id, call_id, onSuccess, errorCallback) => {
            try {
                await addCallParticipantAiCall(
                    users_id, call_id,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getCallDetails = useCallback(
        async (uuid, onSuccess, errorCallback) => {
            try {
                await getCallDetailsApiCall(
                    uuid,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const getCalls = useCallback(
        async (onSuccess, errorCallback) => {
            try {
                await getCallsForCurrentUserApiCall(
                    function (response) {
                        handleCallsResponse(response)
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );

    const handleCallsResponse = (response) => {
        const sortedCalls = response.data.calls.sort((a, b) => moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1)
        callsRef.current = sortedCalls;
        setCalls(sortedCalls)
    }

    const deleteCall = useCallback(
        async (callid, onSuccess, errorCallback) => {
            try {
                await deleteCallAPICall(
                    callid,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const transcribeAudio = useCallback(
        async (formData, onSuccess, errorCallback) => {
            try {
                await transcribeAPICall(
                    formData,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );
    const speakAudio = useCallback(
        async (text, onSuccess, errorCallback) => {
            try {
                await speakAudioAPICall(
                    text,
                    function (response) {
                        onSuccess(response);
                    },
                    function (error) {
                        errorCallback(error);
                    }
                );
            } catch (err) {
                errorCallback(err);
            }
        }
    );


    return (
        <CallsContext.Provider
            value={{
                calls,
                callsRef,

                getCalls,
                getCallDetails,
                deleteCall,
                transcribeAudio,
                speakAudio,
                setCalls,
                createGroupCall,
                addCallParticipant,
                callOfflineParticipant
            }}
        >
            {children}
        </CallsContext.Provider>
    );
};

export const useCallsService = () => useContext(CallsContext);

export default CallsContextProvider;
