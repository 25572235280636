import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'


const SmallOwnCameraComponent = ({ participant, containerRef }) => {
    let tracks = participant.getTrackPublications();
    // const [vTrack, setVTrack] = useState(null);

    const videoRef = useRef(null);



    const [position, setPosition] = useState({ x: 30, y: 30 });
    const [isDragging, setIsDragging] = useState(false);
    const [startPos, setStartPos] = useState({ x: 0, y: 0 });



    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPos({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
    };

    const handleMouseMove = (e) => {
        if (!isDragging || !containerRef.current) return;

        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();

        const newX = e.clientX - startPos.x;
        const newY = e.clientY - startPos.y;

        // Calculate boundaries
        const maxX = containerRect.width - 350; // Subtracting the component width
        const maxY = containerRect.height - 200; // Subtracting the component height

        // Constrain position within boundaries
        setPosition({
            x: Math.max(0, Math.min(newX, maxX)),
            y: Math.max(0, Math.min(newY, maxY)),
        });
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleResize = () => {
        if (!containerRef.current) return;

        const container = containerRef.current.getBoundingClientRect();

        const newX = Math.min(position.x, container.width - 350);
        const newY = Math.min(position.y, container.height - 200);

        setPosition({
            x: Math.max(0, newX),
            y: Math.max(0, newY),
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [position]);

    useEffect(() => {
        let videoTrack = tracks.find((track) => track.kind === "video" && track.track != null)
        if (videoTrack && videoTrack?.track) {
            videoTrack.track.attach(videoRef.current);
        }
        else {
        }

    }, [tracks])

    return (
        <ConnectingContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
            style={{
                left: position.x,
                top: position.y
            }}
        >
            <VideoWrapper>
                <video
                    ref={videoRef}
                    autoPlay
                    muted
                    playsInline

                />
            </VideoWrapper>
        </ConnectingContainer>
    )
}

export default SmallOwnCameraComponent

const ConnectingContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
max-width:350px;
max-height:200px;
position:absolute;
border-radius:10px;
cursor:move;
box-shadow: -1px 4px 4px 0px rgba(0, 0, 0, 0.25);
overflow:hidden;
`

const VideoWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:100%;
overflow:hidden;
video{
object-fit:cover;
min-width:350px;
min-height:200px;

}
`