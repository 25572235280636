import { Route, Routes, useLocation, useNavigate } from "react-router";
import NotFound from "./Pages/NotFound";
import ChatPage from "./Pages/ChatPage";
import PrivateRoute from "./Router/PrivateRoute";

import { Toaster } from "react-hot-toast";
import LoginPage from "./Pages/LoginPage";
import SpinnerComponent from "./Components/GenericComponents/SpinnerComponent";
import { useEffect, useState } from "react";
import ConversationContextProvider from "./Context/ConversationsContext";
import LocalStorage from "./Helpers/LocalStorage";
import { localizationStrings } from "./Localization/Localization";
import WebSocketServiceProvider from "./Context/WebsocketContext";
import OfflineView from "./Components/GenericComponents/OfflineView";
import { useOnlineStatus } from "./Helpers/useOnlineStatus";
import InitializeAnalytics from "./Components/Analytics/InitializeAnalytics";
import ReactGA from "react-ga4";
import QuickChatPage from "./Pages/QuickChatPage";
import QuickChatContextProvider from "./Context/QuickChatContext";
import CallsContextProvider from "./Context/CallsContext";
import QcWebSocketServiceProvider from "./Context/QuickChatWebsocketContext";
import ElectronLoginPage from "./electron/ElectronLogin";
import { useAuthService } from "./Context/AuthContext";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from "./firebase";
import { createSearchParams, useSearchParams } from "react-router-dom";
import DesktopLoginSuccess from "./Pages/DesktopLoginSuccess";
import SsoAuthPage from "./Pages/SsoAuthSuccess";
import OnboardingPage from "./Pages/OnboardingPage";
import styled from "styled-components";
import LogoutDeletedUser from "./Pages/LogoutDeletedUser";
import { useRef } from "react";

function App() {
  const {
    getCurrentUserNoRedirect,
    singOutFirebase,
    user,
    getCustomToken,
    handleSignOutUser,
  } = useAuthService();
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParasm] = useSearchParams();
  const isOnline = useOnlineStatus();
  const isElectron = !!window.electron;
  const navigate = useNavigate();
  var sso_auth = LocalStorage.getSSOFlag();

  const disableAuthCallback = useRef(false);

  const verifyAppLanguage = () => {
    const language = LocalStorage.getAppLanguage();
    if (language) {
      localizationStrings.setLanguage(language);
    }
  };

  const setFirebaseTokens = async (completion) => {
    const auth = getAuth();
    const authUser = auth.currentUser;
    if (authUser) {
      LocalStorage.setAuthenthicationToken(authUser.accessToken);
      LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);
    }

    completion();
  };

  const authObserver = () => {
    if (sso_auth !== "true" || !sso_auth) {
      onAuthStateChanged(auth, (authUser) => {
        if (authUser) {
          const uid = authUser.uid;
          LocalStorage.setAuthenthicationToken(authUser.accessToken);
          LocalStorage.setRefreshToken(authUser.stsTokenManager.refreshToken);

          if (disableAuthCallback.current) {
            return true;
          }

          if (searchParams.get("desktop_login") === "true") {
            getCustomToken(
              (response) => {
                setSearchParasm((prev) => {
                  prev.set("access_token", response.data.authentication_token);
                });
                navigate({
                  pathname: "/desktop_login_success",
                  search: createSearchParams(searchParams).toString(),
                });
              },
              (error) => {}
            );
          } else {
            if (!user) {
              getCurrentUserNoRedirect(
                function () {
                  navigate("/");
                },
                function () {}
              );
            }
          }
        } else {
          singOutFirebase();
        }
      });
    } else if (sso_auth && sso_auth === "true") {
      getCurrentUserNoRedirect(
        (response) => {
          const userResponse = response.data.user;
          if (userResponse.language != null) {
            navigate("/");
          } else {
            navigate("/onboarding");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };
  useEffect(() => {
    // TODO: ENABLE WHEN ENABLE APP LANGUAGE SELECTION
    // verifyAppLanguage();
    ReactGA.set({ user_id: null });
    //Firebase Messaging request
  }, []);

  useEffect(() => {
    authObserver();
  }, [sso_auth]);

  return (
    <Wrapper>
      {!isOnline && <OfflineView />}
      <div id="recaptcha-container"></div>
      <SpinnerComponent isLoading={loading}>
        <Toaster />
        <InitializeAnalytics />
        <Routes>
          <Route path="*" element={<NotFound />}></Route>
          {/* Conditionally render the login route */}
          <Route
            path="/login"
            element={
              isElectron ? (
                <ElectronLoginPage />
              ) : (
                <LoginPage
                  disableAuthCallback={disableAuthCallback}
                  setFirebaseTokens={setFirebaseTokens}
                />
              )
            }
          />
          <Route
            path="/desktop_login_success"
            element={<DesktopLoginSuccess />}
          />
          <Route path="/sso-authentication" element={<SsoAuthPage />} />
          <Route path="/logout-deleted-user" element={<LogoutDeletedUser />} />
          <Route path="/onboarding" element={<OnboardingPage />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <ConversationContextProvider>
                  <CallsContextProvider>
                    <WebSocketServiceProvider>
                      <ChatPage setLoading={setLoading} loading={loading} />
                    </WebSocketServiceProvider>
                  </CallsContextProvider>
                </ConversationContextProvider>
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/quick-chat/:hashId"
            element={
              <QuickChatContextProvider>
                <ConversationContextProvider>
                  <QcWebSocketServiceProvider>
                    <QuickChatPage setLoading={setLoading} />
                  </QcWebSocketServiceProvider>
                </ConversationContextProvider>
              </QuickChatContextProvider>
            }
          ></Route>
        </Routes>
      </SpinnerComponent>
    </Wrapper>
  );
}

export default App;
const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  // min-height:600px;
`;
