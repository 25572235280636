import { useLocalParticipant, useRoomContext } from "@livekit/components-react";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ParticipantComponentFocusedTranslationEnabled from "./ParticipantComponentFocusedTranslationEnabled";
import ParticipantComponentTranslationEnabled from "./ParticipantComponentsTranslationEnabled";
import { cloneDeep } from "lodash";

const ParticipantsWrapperTranslationGrid = ({ callInfo, participants }) => {
  const room = useRoomContext();
  const localParticipant = useLocalParticipant();
  const [speakingParticipant, setSpeakingParticipant] = useState(
    localParticipant.localParticipant
  );
  const filteredParticipantsRef = useRef(participants);
  const [filteredParticipants, setFilteredParticipants] = useState(
    filteredParticipantsRef.current
  );

  const calculateColumns = (length) => {
    if (length <= 4) return 2; // Maximum 2 items per row for 4 or fewer items
    if (length <= 6) return 3; // Maximum 3 items per row for up to 6 items
    return 4; // Maximum 4 items per row otherwise
  };

  const handleParticipantSpeaking = (participant) => {
    participant.on("isSpeakingChanged", () => {
      if (participant.isSpeaking) {
        setSpeakingParticipant(participant);
      }
    });
  };
  useEffect(() => {
    participants.forEach((participant) => {
      handleParticipantSpeaking(participant);
    });
  }, [room]);

  useEffect(() => {
    console.log("participants_changed", participants);
  }, [participants]);

  useEffect(() => {
    let filteredparticipants = participants.filter(
      (p) => p.identity !== speakingParticipant.identity
    );
    filteredParticipantsRef.current = filteredparticipants;
    setFilteredParticipants(filteredparticipants);
  }, [speakingParticipant, participants]);

  return (
    <Wrapper>
      <FocusViewContainer>
        <FocusWrapper>
          <ParticipantComponentFocusedTranslationEnabled
            participant={speakingParticipant}
            callInfo={callInfo}
            key={speakingParticipant.identity}
            grid={false}
            focused={true}
          />
        </FocusWrapper>
        <GridViewContainer columns={2}>
          {filteredParticipants.map((participant) => (
            <ParticipantComponentTranslationEnabled
              participant={participant}
              callInfo={callInfo}
              key={participant.identity}
            />
          ))}
        </GridViewContainer>
      </FocusViewContainer>
    </Wrapper>
  );
};

export default ParticipantsWrapperTranslationGrid;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 900px;
`;

const GridViewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-auto-rows: 1fr;
  width: 100%;
  height: 100%;
  gap: 10px;
`;
const FocusViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  gap: 20px;
  padding: 5px;
`;

const FocusWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 300px;
  overflow: hidden;
`;
