
import React, { useEffect, useReducer, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { Rating } from '@mui/material'
import { localizationStrings } from '../../Localization/Localization'

const RatingModal = ({ sendRating, setShowRatingCard, showRatingCard }) => {
    const [ratingValue, setRatingValue] = useState(0);
    const ref = useRef();

    const handleRating = (rating) => {
        sendRating(rating);

    }


    return (
        <FullScreenItem>
            <RatingCard ref={ref}>
                <h1>{localizationStrings.chat.rating_modal_message} </h1>
                <Rating
                    name="simple-controlled"
                    value={ratingValue}
                    size='large'
                    className='rating-custom'
                    onChange={(event, newValue) => {
                        handleRating(newValue)
                        // setRatingValue(newValue);
                    }}
                />
            </RatingCard>


        </FullScreenItem>

    )
}

export default RatingModal

const FullScreenItem = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:fixed;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
`
const RatingCard = styled.div`
display:flex;
flex-direction:column;
gap:30px;
min-height:200px;
max-width:400px;
background:${colors.receivedMessageBg};
border-radius:8px;
padding:30px;
align-items:center;
h1{
    font-size:20px;
    line-height:24px;
    font-weight:600;
    text-align:center;
    color:${colors.white};
}
.rating-custom{
    span{
        font-size:40px;
        color:${colors.purple};
    }
}
`