import React from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { ReactComponent as GridIcon } from "../../Assets/icons/callControlButtons/gridButton.svg";
import {
  useRemoteParticipant,
  useRemoteParticipants,
} from "@livekit/components-react";

const CallHeader = ({ callDetails, handleSwitchGrid, translationEnabled }) => {
  const remoteParticipants = useRemoteParticipants();

  const displayHeaderName = () => {
    if (callDetails.group) {
      return callDetails.group.name;
    } else {
      let string = remoteParticipants.map((user) => user.name).join(", ");
      return string;
    }
  };
  return (
    <Container>
      <Name>{displayHeaderName()}</Name>
      {!translationEnabled && (
        <GridButton onClick={handleSwitchGrid}>
          <GridIcon />
        </GridButton>
      )}
    </Container>
  );
};

export default CallHeader;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 40px;
  align-items: center;
  gap: 20px;
  overflow: hidden;
`;
const Name = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: ${colors.callControlBar.buttonLabelColor};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const GridButton = styled.button`
  display: flex;
  margin-left: auto;
  cursor: pointer;
  background: none;
  border: none;

  svg {
    max-width: 30px;
  }
`;
