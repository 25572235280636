import React, { useEffect } from 'react'
import styled from 'styled-components'
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg"
import { ReactComponent as NoAvatar } from "../../Assets/icons/calls/noAvatar.svg"
import { colors } from '../../Assets/styles/colors'
import { displayName } from '../../Helpers/chatHelper/NameHelper'
import { localizationStrings } from '../../Localization/Localization'
import { callingSoundHowl } from './CallView'
import { useRemoteParticipants, useRoomContext } from '@livekit/components-react'

const ConnectingView = ({ callInfo, handleCloseCallBtn, resetCall, sendCloseCallMessage }) => {
    const room = useRoomContext();
    const remoteParticipant = useRemoteParticipants();



    const displayCallAvatar = (data) => {

        if (!data) {
            return <NoAvatar />
        }

        let displayAvatar = <></>
        let profilePicture = data?.other_participants[0]?.profile_picture;

        if (data.group != null) {
            profilePicture = data.group?.image
        }

        if (profilePicture) {
            displayAvatar = <img src={profilePicture} alt="avatar" />
        } else {
            displayAvatar = <NoAvatar />
        }

        return displayAvatar

    }

    useEffect(() => {
        // Run the function after 20 seconds
        const timer = setTimeout(() => {
            sendCloseCallMessage(callInfo.uuid);
            callingSoundHowl.stop();
            room.disconnect();
            resetCall();
        }, 20000); // 20000 ms = 20 seconds

        // Clean up the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this effect runs only once




    return (
        <ConnectingContainer>
            <AvatarWrapper>
                <UserAvatar>
                    {displayCallAvatar(callInfo)}
                </UserAvatar>
                <Name>{displayName(callInfo)}</Name>
                <Status>
                    {localizationStrings.call_modal.connecting}
                </Status>
            </AvatarWrapper>
            <CloseCallBtn onClick={handleCloseCallBtn}>
                <CloseCall />
            </CloseCallBtn>



        </ConnectingContainer>
    )
}

export default ConnectingView
const ConnectingContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;

width:100%;
height:100%;
padding:30px;
background:${colors.callControlBar.bg};

`
const CloseCallBtn = styled.button`
background:none;
border:none;
margin-top:auto;
cursor:pointer;
`

const AvatarWrapper = styled.div`
display:flex;
align-items:center;
justify-content:center;
gap:10px;
margin-top:auto;
flex-direction:column;

`
const Status = styled.p`
font-size:16px;
line-height:20px;
color:${colors.callControlBar.buttonLabelColor};
`
const Name = styled.h3`
font-size:40px;
line-height:45px;
color:${colors.white};
margin-top:30px;
`


const UserAvatar = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  cursor: pointer;
  object-fit: contain;
  position: relative;
  overflow: hidden;

  max-height: 60px;
  max-width: 60px;
  min-height: 60px;
  min-width: 60px;
  svg {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
     object-fit:cover;
    position: relative;
    height: 60px;
    width: 60px;
  }
`;