import {
  SUBSCRIPTION_LEVEL_ULTRA,
  SUBSCRIPTION_LEVEL_PLATFORM,
  SUBSCRIPTION_LEVEL_PRO,
  SUBSCRIPTION_LEVEL_REFERRALS,
  SUBSCRIPTION_LEVEL_UNSUBSCRIBED,
  ACCESS_LEVEL_PLATFORM,
  ACCESS_LEVEL_ULTRA,
  ACCESS_LEVEL_PRO,
} from "../../Constants/constantValues";

export const ACCESS_LEVEL = Object.freeze({
  PRO: ACCESS_LEVEL_PRO,
  ULTRA: ACCESS_LEVEL_ULTRA,
  PLATFORM: ACCESS_LEVEL_PLATFORM,
});

const SUBSCRIPTION_LEVEL_PRIORITY = {
  [SUBSCRIPTION_LEVEL_UNSUBSCRIBED]: 0,
  [SUBSCRIPTION_LEVEL_REFERRALS]: 1, // Same as PRO
  [SUBSCRIPTION_LEVEL_PRO]: 1,
  [SUBSCRIPTION_LEVEL_ULTRA]: 2,
  [SUBSCRIPTION_LEVEL_PLATFORM]: 3,
};

const ACCESS_LEVEL_PRIORITY = {
  [ACCESS_LEVEL.PRO]: 1,
  [ACCESS_LEVEL.ULTRA]: 2, // Same as PRO
  [ACCESS_LEVEL.PLATFORM]: 3,
};

export const isSubscriptionLevelEnoughForAccessLevel = (
  subscriptionLevel,
  accessLevel
) => {
  const subscriptionPriority = SUBSCRIPTION_LEVEL_PRIORITY[subscriptionLevel];
  const accessPriority = ACCESS_LEVEL_PRIORITY[accessLevel];

  // Return false if subscription level is not enough or invalid levels are provided
  return subscriptionPriority >= accessPriority;
};
