import { useLocalParticipant, useRoomContext } from "@livekit/components-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ParticipantComponent from "./ParticipantComponent";
import ScreenShareParticipantComponent from "./ScreenShareParticipantComponent";
import ScreenShareParticipant from "./ScreenShareParticipant";

const ScreenSharingGrid = ({
    callInfo,
    participants,
    screenSharingParticipant,
    screenSharingParticipants,
    handleSelectScreenShareParticipant
}) => {

    const isParticipantElementScreenShareDisabled = screenSharingParticipants.length > 0

    return (
        <Wrapper>
            <FocusViewContainer>

                <ParticipantsListWrapper>
                    {screenSharingParticipants.map((screenShareParticipant, index) => (
                        <ScreenShareParticipant
                            screenShareParticipant={screenShareParticipant}
                            callInfo={callInfo}
                            key={index}
                            handleSelectScreenShareParticipant={handleSelectScreenShareParticipant}
                        />
                    ))
                    }
                    {participants.map((participant) => (
                        <ParticipantComponent
                            participant={participant}
                            callInfo={callInfo}
                            key={participant.identity}
                            grid={true}
                            disableScreenShareDisplay={isParticipantElementScreenShareDisabled}
                        />
                    ))}
                </ParticipantsListWrapper>

                <FocusWrapper>
                    <ScreenShareParticipantComponent
                        participant={screenSharingParticipant.participant}
                        callInfo={callInfo}
                        key={screenSharingParticipant.identity}
                        grid={false}
                        focused={true}
                    />
                </FocusWrapper>
            </FocusViewContainer>
        </Wrapper>
    );
};

export default ScreenSharingGrid;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const FocusViewContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  gap: 20px;
  padding: 5px;
`;

const ParticipantsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  width: 100%;
  min-width: 200px;
  max-width: 250px;
  gap: 10px;
`;

const FocusWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
