import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from "../../Assets/icons/closeIcon.svg";
import _ from 'lodash';
import { localizationStrings } from '../../Localization/Localization';

const Element = ({ element, selected, text, handleElementClick, purple, noBorder, readonly }) => {
    return (
        <ElementWrapper onClick={readonly ? () => { } : (e) => handleElementClick(element)} noBorder={noBorder} readonly={readonly}>
            <ElementText purple={purple} >
                <p>{text}</p>
            </ElementText>
            <CustomCheckBox >
                <label className="container" >
                    <input
                        type="checkbox"
                        checked={selected}
                        readOnly

                    />
                    <span
                        className="checkmark">
                    </span>
                </label>
            </CustomCheckBox>
        </ElementWrapper>
    )
}

const DeleteReasonsModal = ({ handleClose, setDeleteConfirmationOpen, selectedReasons, setSelectedReasons }) => {

    const [confirmation, setConfirmation] = useState(null)

    const reasonsList = [
        {
            reason: localizationStrings.delete_account_modal.reason1,
            reason_id: 1
        },
        {
            reason: localizationStrings.delete_account_modal.reason2,
            reason_id: 2
        },
        {
            reason: localizationStrings.delete_account_modal.reason3,
            reason_id: 3
        },
        {
            reason: localizationStrings.delete_account_modal.reason4,
            reason_id: 4
        },
        {
            reason: localizationStrings.delete_account_modal.reason5,
            reason_id: 5
        },
    ]

    const confirmationElement = {
        reason: "Confirmation",
        reason_id: 9
    }

    const handleElementClick = (element) => {
        let found = selectedReasons.find((item) => item.reason_id === element.reason_id)
        if (found) {
            let filtered = selectedReasons.filter((item) => item.reason_id != element.reason_id)
            setSelectedReasons(filtered)
        }
        else {
            setSelectedReasons((prev) => {
                return [...prev, element]
            })
        }
    }

    const handleConfirmation = (element) => {

        if (_.isEqual(confirmation, element)) {
            setConfirmation(null)
        } else {
            setConfirmation(element)
        }
    }

    useEffect(() => {
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();
                // 👇️ your logic here
                handleClose();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <FullScreenContainer>
            <Card>
                <Header>
                    <Text>
                        <h1>{localizationStrings.delete_account_modal.delete}</h1>
                    </Text>
                    <CloseBtn onClick={handleClose}>
                        <CloseIcon />
                    </CloseBtn>
                </Header>
                <InfoText>
                    <p>{localizationStrings.delete_account_modal.are_you_sure}</p>
                    <p>{localizationStrings.delete_account_modal.action}</p>
                    <p>{localizationStrings.delete_account_modal.specifically}</p>
                </InfoText>
                <InfoText>
                    <h1>{localizationStrings.delete_account_modal.reasons}</h1>
                    <p>{localizationStrings.delete_account_modal.reasons_info}</p>
                </InfoText>
                <Content>
                    {reasonsList.map((element) => (
                        <Element
                            key={element.id}
                            text={element.reason}
                            element={element}
                            handleElementClick={handleElementClick}
                            selected={selectedReasons.find((item) => item.reason_id === element.reason_id)}
                        />
                    ))}
                </Content>
                <InfoText>
                    <h1>{localizationStrings.delete_account_modal.confirmation}</h1>
                    <Element
                        element={confirmationElement}
                        handleElementClick={handleConfirmation}
                        purple
                        noBorder
                        text={localizationStrings.delete_account_modal.i_want}
                        selected={confirmation?.reason_id === confirmationElement.reason_id}
                    />
                </InfoText>
                <DeleteAccountButton
                    disabled={selectedReasons.length == 0 || !confirmation}
                    onClick={() => setDeleteConfirmationOpen(true)}
                >
                    {localizationStrings.profile.delete_account}
                </DeleteAccountButton>
            </Card>
        </FullScreenContainer>
    )
}

export default DeleteReasonsModal
const FullScreenContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:fixed;
width:100%;
height:100%;
background:rgba(0,0,0,0.3);
left:0;
top:0;
padding:5vh 40vw;
z-index:2;
`
const Card = styled.div`
display:flex;
flex-direction:column;
gap:30px;
padding:25px 20px;
background:${colors.newChatModalBg};
width:100%;
height:fit-content;
min-width:600px;
border-radius:10px;
max-height:100%;
overflow:auto;
`
const Header = styled.div`
display:flex;
justify-content:space-between;

`
const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
`
const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: px;
overflow:hidden;
h1{
  text-transform:capitalize;
    color:${colors.white};
font-size: 20px;
font-weight: 500;
line-height: 30px;
text-align: left;

}
`;
const Content = styled.div`
display:flex;
flex-direction:column;
height:100%;
overflow:auto;

`
const InfoText = styled.div`
display:flex;
flex-direction:column;
gap:10px;
p{
color:${colors.chatElementColor};
font-size: 14px;
font-weight: 500;
line-height: 18px;
text-align: left;
}
h1{
    text-transform:capitalize;

 font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${props => props.purple ? colors.purple : colors.white};
  width: 100%;
  
  }
`
const CustomCheckBox = styled.div`
margin-left:auto;
display:block;
z-index:-1;
  .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-left: 35px;
    margin-bottom: 25px;
    cursor: pointer;
    font-size: 22px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;

  }

  /* Hide the browser's default checkbox */
  .container input {
    opacity: 0;
    // cursor: inherit;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    z-index:0;
    top: 0;
    left: 0;
    height: 26px;
    width: 26px;
    border: 1px solid ${colors.purple};
    border-radius: 50%;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: rgba(112, 105, 253, 1);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ElementWrapper = styled.div`
display:flex;
z-index:1;
align-items:center;
border-bottom:${props => props.noBorder ? "none" : ` 1px solid ${colors.newChatModalInputBg}`};
cursor:${props => props.readonly ? "inherit" : "pointer"};
`
const ElementText = styled.div`
display: flex;
align-items: center;
padding: 15px 0;
p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: ${props => props.purple ? colors.purple : colors.white};
  width: 100%;
}

`

const DeleteAccountButton = styled.button`
display: flex;
align-items: center;
justify-content:center;
gap: 3px;
background: ${colors.purple};
color: ${colors.white};
font-size: 16px;
font-weight: 600;
line-height: 22px;
text-align: center;
border: none;
border-radius: 10px;
padding: 14px 15px;
cursor: pointer;
svg {
  height: 20px;
  width: 20px;
}
&:disabled{
    cursor:inherit;
    opacity:0.3;
}
`;