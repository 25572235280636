import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ReactComponent as CloseCall } from "../../Assets/icons/calls/closeCall.svg";
import { ReactComponent as NoAvatar } from "../../Assets/icons/calls/noAvatar.svg";
import { colors } from "../../Assets/styles/colors";
import { displayName } from "../../Helpers/chatHelper/NameHelper";
import { useAuthService } from "../../Context/AuthContext";
import { useIsSpeaking, useParticipantTile } from "@livekit/components-react";

const ScreenShareParticipantComponent = ({
    participant,
    callInfo,
    grid,
    focused,
}) => {
    const divRef = useRef(null);

    let tracks = participant.getTrackPublications();
    const [vTrack, setVTrack] = useState(null);
    const videoRef = useRef(null);


    useEffect(() => {
        let videoTracks = tracks.filter(
            (track) => track.kind === "video" && track.track != null
        );

        if (videoTracks.length > 0) {
            let screenShareTrack = videoTracks.find(
                (track) => track.source === "screen_share" && track.track != null
            );
            let videoTrack = videoTracks.find(
                (track) => track.source === "camera" && track.track != null
            );

            if (screenShareTrack && screenShareTrack?.track) {
                setVTrack(screenShareTrack);
                screenShareTrack.track.attach(videoRef.current);
            } else {
                if (videoTrack && videoTrack?.track) {
                    setVTrack(videoTrack);
                    videoTrack.track.attach(videoRef.current);
                } else {
                    setVTrack(null);
                }
            }
        } else {
            setVTrack(null);
        }
    }, [tracks]);


    return (
        <ConnectingContainer
            ref={divRef}
            grid={grid}
        >
            <VideoContainer>
                <video ref={videoRef} autoPlay muted playsInline />
            </VideoContainer>
        </ConnectingContainer>
    );
};

export default ScreenShareParticipantComponent;
const ConnectingContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  max-height:100%;
  overflow: hidden;
  border-radius: 10px;
  border: 3px solid ${colors.purple};
`;

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  video {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
`;
