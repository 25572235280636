
import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as RightArrow } from "../../Assets/icons/dropDownArrow.svg";
import LanguageListSelector from './LanguageListSelector';
import { getFlag } from '../../Helpers/FlagHandler';

const LanguageBtn = ({ value, setValue, placeholder, placeholderIcon, valuesList, callLanguage }) => {

    let foundValue = valuesList.find(item => item.name === value)

    if (callLanguage) {
        foundValue = valuesList.find(item => item.locale === value)
    }

    const [isLanguagelistOpen, setIsLanguageListOpen] = useState(false);

    const openLanguageList = () => {
        setIsLanguageListOpen(!isLanguagelistOpen)
    }

    return (
        <Container>
            {isLanguagelistOpen &&
                <LanguageListSelector
                    setValue={setValue}
                    valuesList={valuesList}
                    openLanguageList={openLanguageList}
                    callLanguage={callLanguage}
                />
            }
            <ButtonContainer
                onClick={openLanguageList}>
                {value ? (
                    <>
                        <Flag src={getFlag(foundValue.country_code)} alt={foundValue.name} />
                        <ValueName>{`${foundValue.name} (${foundValue.translation})`}</ValueName>
                        <RightArrow style={{ marginLeft: "auto", width: "14px" }} />
                    </>
                )
                    : (
                        <>
                            {placeholderIcon}
                            <ValueName disabled>{placeholder}</ValueName>
                            <RightArrow style={{ marginLeft: "auto", width: "14px" }} />
                        </>
                    )
                }


            </ButtonContainer>

        </Container>


    )
}

export default LanguageBtn

const Container = styled.div`

`
const ButtonContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
padding:16px;
  border-radius: 10px;
  background: ${colors.newChatModalInputBg};
cursor:pointer;
`
const ValueName = styled.h3`
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: ${colors.white};


${props => props.disabled && (
        `
    opacity:0.3;

    `
    )}

     `


const Flag = styled.img`
     width:20px;
     height:20px;
     display:flex;
     object-fit:contain;
     `